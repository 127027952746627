import React from "react";
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import validator from "validator";

import keys from "configs/constants";
import api from "services/backendService";
import utils from "utils";
import { withTranslation } from "react-i18next";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";

class AlarmFastSetup extends React.Component {
  static contextType = AuthorizationContext;
  state = { enableAllForDevice: true, receivers: "", receiversError: false };

  onInputChange(evt) {
    let error = false;
    const target = evt.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "receivers") {
      for (const r of utils.getAlertReceiversArray(value)) {
        if (validator.isEmail(r) === false) {
          error = true;
          break;
        }
      }
    }
    this.setState({ [name]: value, receiversError: error });
  }

  async onSaveChangesClicked() {
    this.props.toggleLoader();
    const receivers = utils.getAlertReceiversArray(this.state.receivers);

    for (const dvc of this.props.dvcs) {
      let th = [];
      for (const point of Object.values(dvc.pointsById)) {
        if (point.vtype === "alarm") {
          /* This setting will create a Digital Threshold active on Rising Edge */
          th.push({
            deviceGuid: dvc.deviceGuid,
            label: utils.getLangText(point.code, dvc.customizationData),
            fieldName: point.code,
            maxValue: 0.5,
            minValue: -1,
            rangePercentage: 1,
          });
        }
      }
      /* Automatically add No Link Digital Threshold */
      th.push({
        deviceGuid: dvc.deviceGuid,
        label: utils.getLangText("noLink", dvc.customizationData),
        fieldName: "noLink",
        maxValue: 0.5,
        minValue: -1,
        rangePercentage: 1,
      });
      if (th.length) {
        const thdata = (await api.createNewThresholds(th)).data.data;
        if (receivers.length > 0) {
          let thNotify = [];
          for (const threshold of thdata) {
            for (const r of receivers) {
              thNotify.push({
                enableNotification: "ON",
                thresholdGuid: threshold.thresholdGuid,
                email: r,
                organizationId: this.context.activeOrganization.guid,
                siteId: this.props.selectedSiteGuid
              });
            }
          }
          await api.createOrUpdateThresholdNotify(thNotify);
        }
      }
    }
    if (this.props.updateStore) this.props.updateStore();
  }

  render() {
    return (
      <Form>
        <div className="h4 mb-3 font-weight-200">
          {this.props.t("alarmFastSetup.noAlerts") +
            ' "' +
            this.props.t("alarmFastSetup.saveAndEnable") +
            '"'}
        </div>
        <div className="h4 mb-3 font-weight-200">
          {this.props.t("alarmFastSetup.afterThisStep")}
        </div>
        {/* <div className="custom-control custom-checkbox mb-3">
          <input
            className="custom-control-input"
            id="enableAllForDevice"
            name="enableAllForDevice"
            type="checkbox"
            checked={this.state.enableAllForDevice}
            onChange={this.onInputChange.bind(this)}
          />
          <label className="custom-control-label" htmlFor="enableAllForDevice">
            Enable Alerts For All Controllers
          </label>
        </div> */}
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupText id="group-name">
              {keys.ICON_RECEIVERS}
            </InputGroupText>
            <Input
              placeholder={this.props.t(
                "alarmFastSetup.multipleReceiversPlaceHolder"
              )}
              id="receivers"
              name="receivers"
              type="email"
              onChange={this.onInputChange.bind(this)}
              value={this.state.receivers}
              className={
                this.state.receiversError ? " bg-warning text-white " : null
              }
            />
          </InputGroup>
          <div className="text-center">
            <Button
              className="ml-md-5 btn-icon"
              color="primary"
              type="button"
              disabled={
                /*this.state.receivers === "" || */ this.state.receiversError
              }
              onClick={this.onSaveChangesClicked.bind(this)}
            >
              <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
              <span className="btn-inner--text">
                {this.props.t("alarmFastSetup.saveAndEnable")}
              </span>
            </Button>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export default withTranslation("common")(AlarmFastSetup);
