import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Wallet from "../components/Wallet";

function UserWallet( props) {
  return  <Wallet {...props} />;
}

const mapStateToProps = () => ({});

export default withTranslation("common")(connect(mapStateToProps)(UserWallet));
