import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { TooltipButton } from "../components/Tooltip";
import PointBox from "../components/PointBox";
import utils from "../utils";

import keys from "configs/constants";
import { withTranslation } from "react-i18next";
import Loader from "./Loader";
class ConnectedDevice extends React.Component {
  statusIcon(iconState) {
    let v;
    switch (iconState) {
      case "ok":
        v = (
          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
            {keys.ICON_CONTROLLER_ALLGOOD}
          </div>
        );
        // code block
        break;
      case "alarm":
        v = (
          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
            {keys.ICON_CONTROLLER_ALARM}
          </div>
        );
        // code block
        break;
      case "info":
        v = (
          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
            {keys.ICON_CONTROLLER_ADVICE}
          </div>
        );
        // code block
        break;
      default:
        v = null;
    }
    return v;
  }

  showPoints = () => {
    const noMeasures = (
      <>
        <Col className="text-center">
          <span className="font-weight-light">
            {this.props.t("connectedDevice.noMeasuresYet")}
          </span>
        </Col>
      </>
    );

    let { points } = this.props;
    let pointsObj = Object.getOwnPropertyNames(this.props.points);

    // If all point values are undefined (no registered measures into db) show 'No measures yet' message.
    if (
      pointsObj.filter(
        (x) =>
          points[x].lastValue === undefined && points[x].value === undefined,
      ).length === pointsObj.length
    )
      return noMeasures;

    // If all point values are in error (-32767) show 'BAD LINK' for all overview points.
    const badLink =
      pointsObj.filter((x) => {
        return points[x].value === -32767;
      }).length === pointsObj.length;

    let deviceOverviewPoints = pointsObj.filter(
      (p) => points[p].tags && points[p].tags.deviceOverview,
    );

    return deviceOverviewPoints.map((p) => {
      let val = "NA";
      if (!badLink) {
        if (points[p].value || points[p].value === 0) {
          val = utils.pointValue(
            points[p].value,
            points[p].type,
            points[p].vtype,
          );
        } else {
          if (points[p].lastValue || points[p].lastValue === 0) {
            val = utils.pointValue(
              points[p].lastValue,
              points[p].type,
              points[p].vtype,
            );
          }
        }
      } else {
        val = "BAD LINK";
      }

      const missing = val === "NA" || val === "NO PROBE";
      const { customizationData } =
        this.props.controllers.byId[this.props.guid];

      return (
        <PointBox
          key={points[p].id + "-" + this.props.guid}
          name={points[p].code}
          value={missing ? "---" : val}
          vtype={missing ? "" : points[p].vtype}
          icon={points[p].icon}
          type={points[p].type}
          customizationData={customizationData}
        />
      );
    });
  };

  render() {
    if (
      this.props.controllers.byId[this.props.guid] === undefined ||
      this.props.gatewayInfo === undefined
    ) {
      return <Loader />;
    }

    const gw = this.props.gatewayInfo;
    const { isDongleController } = this.props;

    const model = utils.getModelFromMFVE(this.props.name);
    const isUnknown = utils.isModelUnknown(model);
    const name =
      this.props.friendlyName !== null ? this.props.friendlyName : model;

    const siteInfo = {
      siteGuid: this.props.siteGuid,
      gatewayGuid: this.props.gatewayGuid,
      deviceGuid: this.props.guid,
      name: this.props.name,
      city: this.props.city,
      country: this.props.country,
      connectivitytype: this.props.connectivitytype,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      address: this.props.address,
      siteInfo: this.props.siteInfo,
    };

    const gatewayLinkInfo = {
      site: gw.site?.name || "",
      siteGuid: gw.site?.siteGuid || "",
      customer: gw.customer?.name || "",
      name: gw.name,
      gatewayGuid: gw.gatewayGuid,
      macAddress: gw.macAddress,
      devices: this.props.controllers,
    };

    var cardClassName = "card-profile shadow ";
    if (this.props.status === "alarm") cardClassName += "border border-danger";
    /* else if (this.props.gatewayInfo.status === "OFF")
      cardClassName += "border border-warning"; */

    const status = isDongleController
      ? ""
      : this.props.status === "alarm"
        ? "alarm"
        : this.props.gatewayInfo.status === "OFF"
          ? "info"
          : "ok";

    return (
      <>
        <Row className="justify-content-md-center pb-4">
          <Col className="fluid pt-4 mb-5 mb-lg-0">
            <Card className={cardClassName}>
              <CardHeader className="text-center pt-4 pt-md-3 pb-0 pb-md-2">
                <Row>
                  <Col
                    className="col-sm"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    <h3 className="mt-2 device-name">
                      {isUnknown ? this.props.t("generic.unknown") : name}
                    </h3>
                    <span
                      className={`${isDongleController ? "d-none" : ""}  font-weight-light reference`}
                    >
                      {`${this.props.t("connectedDevice.reference")}: ${this.props.address}`}
                    </span>
                  </Col>
                  <Col className="col-sm icon-device-info">
                    {this.statusIcon(status)}
                  </Col>
                  <Col>
                    <Link
                      to={{
                        pathname: isUnknown ? "/std/gtw" : "/std/sv",
                        gateway: isUnknown ? gatewayLinkInfo : null,
                        site: siteInfo,
                      }}
                    >
                      <Button
                        id="btnChartTooltip"
                        className="btn-icon d-none d-md-block float-right"
                        color="info"
                      >
                        <span className="btn-inner--icon">
                          {keys.ICON_CHART}
                        </span>
                        <TooltipButton
                          target="btnChartTooltip"
                          text={this.props.t("connectedDevice.controllerInfo")}
                        />
                      </Button>

                      <Button
                        id="btnChartTooltip"
                        className="btn-icon d-md-none float-right"
                        color="info"
                        size="sm"
                      >
                        <span className="btn-inner--icon">
                          {keys.ICON_CHART}
                        </span>
                        <TooltipButton
                          target="btnChartTooltip"
                          text={this.props.t("connectedDevice.controllerInfo")}
                        />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="shadow p-2">
                <Row>{this.showPoints()}</Row>
              </CardBody>
              {this.props.gatewayInfo.status === "OFF" ? (
                <div className="bg-warning rounded-bottom">
                  <div
                    className={`text-white m-2 ${
                      window.innerWidth > 768 ? "h4" : "h5"
                    }`}
                  >
                    {this.props.t("connectedDevice.gatewayOffline1")}
                    <Link
                      to={{
                        pathname: "/std/gtw",
                        gateway: gatewayLinkInfo,
                        site: siteInfo,
                      }}
                      className="text-yellow"
                    >
                      <u>{" " + this.props.gatewayInfo.name}</u>{" "}
                    </Link>
                    {this.props.t("connectedDevice.gatewayOffline2") + "."}
                    <span
                      className={`${
                        this.props.gatewayInfo.lastDataSentOn === undefined
                          ? "d-none"
                          : ""
                      }`}
                    >
                      {new Date(
                        this.props.gatewayInfo.lastDataSentOn + "Z",
                      ).getYear() > 0
                        ? " " +
                          this.props.t("connectedDevice.lastUpdate") +
                          ": " +
                          new Date(
                            this.props.gatewayInfo.lastDataSentOn + "Z",
                          ).toLocaleString()
                        : ""}
                    </span>
                  </div>
                </div>
              ) : null}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation("common")(ConnectedDevice);
