/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Devices from "views/Devices.js";
import SingleView from "views/SingleView.js";
import RegisterThing from "views/RegisterThing.js";
import CustomersAdmin from "views/admin/CustomersAdmin";
import SitesAdmin from "views/admin/SitesAdmin";
import GatewaysAdmin from "views/admin/GatewaysAdmin";
import DeviceDescriptors from "views/admin/DeviceDescriptors";
import EditSiteProperties from "views/EditSiteProperties";
import EditGatewayProperties from "views/EditGatewayProperties";
import Reports from "views/Reports";
import EditAlarmConfiguration from "views/EditAlarmConfiguration";
import AlarmLog from "views/AlarmLog";
import Permissions from "views/Permissions";
import DeletedEntities from "views/admin/DeletedEntities";
import License from "views/admin/License";

import keys from "configs/constants";

import PerformanceIndicators from "views/PerformanceIndicators";
import ExternalLink from "views/ExternalLink";
import UserWallet from "./views/UserWallet";

var routes = [
  {
    path: "/index",
    name: "dashboard",
    icon: keys.ICON_MENU_DASHBOARD,
    component: Dashboard,
    layout: "/std",
    req: [],
  },
  {
    path: "/alrlg",
    name: "alarmLog",
    icon: keys.ICON_MENU_ALARM_LOG,
    component: AlarmLog,
    layout: "/std",
    menu: true,
    req: [],
    nActiveAlarms: true,
  },
  {
    path: "/dvcs",
    name: "myConnectedControls",
    icon: keys.ICON_MENU_CONTROLLER,
    component: Devices,
    layout: "/std",
    menu: false,
    req: ["controllers"],
  },
  {
    path: "/sstgs",
    name: "editInstallationProperties",
    icon: keys.ICON_MENU_SITE,
    component: EditSiteProperties,
    layout: "/std",
    menu: false,
    req: [],
  },
  {
    path: "/sv",
    name: "connectedControlView",
    icon: keys.ICON_MENU_CONTROLLER,
    component: SingleView,
    layout: "/std",
    menu: false,
    req: ["controllers"],
  },
  {
    path: "/gtw",
    name: "editGatewayProperties",
    icon: keys.ICON_CONTROLLER,
    component: EditGatewayProperties,
    layout: "/std",
    menu: false,
    req: ["controllers"],
  },
  {
    path: "/ac",
    name: "alarmConfiguration",
    icon: keys.ICON_MENU_ALARM_SETTINGS,
    component: EditAlarmConfiguration,
    layout: "/std",
    req: [],
    section: "configuration",
  },
  {
    path: "/kpi",
    name: "performance",
    component: PerformanceIndicators,
    layout: "/std",
    menu: false,
    req: ["gateways"],
    section: "configuration",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // },
  {
    path: "/rpts",
    name: "reports",
    icon: keys.ICON_REPORTS,
    component: Reports,
    layout: "/std",
    menu: false,
    req: [],
  },
  {
    path: "/prmssns",
    name: "accessRights",
    icon: keys.ICON_MENU_VISIBILITY,
    component: Permissions,
    layout: "/std",
    menu: true,
    req: [],
    section: "configuration",
  },
  {
    path: "/lic",
    name: "Wallet",
    icon: keys.ICON_MENU_LICENSE,
    component: UserWallet,
    layout: "/std",
    menu: true,
    req: [],
    section: "configuration",
    license: true,
  },
  {
    path: "",
    name: "registerNewGateway",
    icon: keys.ICON_MENU_ADD_GATEWAY,
    component: RegisterThing,
    menu: false,
    layout: "/tr",
  },
  {
    path: "/cust",
    name: "customers",
    icon: keys.ICON_MENU_CUSTOMER,
    component: CustomersAdmin,
    layout: "/ad",
  },
  {
    path: "/site",
    name: "sites",
    icon: keys.ICON_MENU_SITE,
    component: SitesAdmin,
    layout: "/ad",
  },
  {
    path: "/gws",
    name: "gateways",
    icon: keys.ICON_MENU_GATEWAY,
    component: GatewaysAdmin,
    layout: "/ad",
  },
  {
    path: "/dscs",
    name: "deviceDescriptors",
    icon: keys.ICON_MENU_DEVICE_DESCRIPTOR,
    component: DeviceDescriptors,
    layout: "/ad",
  },
  {
    path: "/deleted",
    name: "deletedEntities",
    icon: keys.ICON_MENU_DEL_ENTITIES,
    component: DeletedEntities,
    layout: "/ad",
  },
  {
    path: "/license",
    name: "license",
    icon: keys.ICON_MENU_LICENSE,
    component: License,
    layout: "/ad",
    superUser: true,
  },
  {
    path: "/",
    name: "externalLink",
    component: ExternalLink,
    layout: "/ext",
    menu: false,
  }
];
export default routes;
