import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";

import SelectSearch, { fuzzySearch } from "react-select-search";

import keys from "configs/constants";
import api from "services/backendService";
import utils from "utils";
import { withTranslation } from "react-i18next";

class GatewaysAdmin extends React.Component {
  state = {
    dataOptions: [],
    site: "",
    gatewayGuid: null,
    selected: false,
    errorState: "",
    total: 0,
    releaseFW: null,
  };

  async componentDidMount() {
    const pload = (await api.getGatewayDetails()).data;
    const ploads = (await api.getSites()).data;

    this.setState({
      dataOptions: pload.data,
      sites: ploads.data,
      total: pload.total,
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  async reloadData(pl) {
    if (!pl.message && (pl.status === 200 || pl.status === 201)) {
      const pload = await api.getGatewayDetails();
      if (pload.message === 500) {
        this.setState({
          dataOptions: pload.data.data,
          response: "Internal Server Error",
          errorState: "Error",
        });
        return;
      }
      this.setState({
        dataOptions: pload.data.data,
        response: pl,
        errorState: "OK",
        total: pload.data.total,
      });
    } else this.setState({ response: pl.message, errorState: "Error" });
  }

  async addData() {
    const pload = await api.addGateway({
      macAddress: this.state.macAddress.trim(),
      name: this.state.name.trim(),
      telemetryPushInterval:
        "" + parseInt(this.state.telemetryPushInterval, 10),
      siteGuid: this.state.siteGuid,
    });
    await this.reloadData(pload);
  }

  async deleteData() {
    const pload = await api.deleteGateway(this.state.selectedGuid, true);
    await this.reloadData(pload);
  }

  async updateData() {
    const pload = await api.updateGateway(
      this.state.selectedGuid,
      this.state.name.trim(),
      parseInt(this.state.telemetryPushInterval, 10),
      this.state.siteGuid
    );
    await this.reloadData(pload);
  }

  onSiteSelected(sGuid) {
    this.setState({ siteGuid: sGuid });
  }

  async onDataSelected(idx) {
    if (idx === 0) {
      this.setState({
        createNew: true,
        selected: true,
        response: null,
        selectedGuid: this.state.dataOptions[idx].gatewayGuid,
        selectedIdx: idx,
        name: "",
        telemetryPushInterval: 300,
        macAddress: "",
        totalDevices: "",
        siteGuid: "",
        errorState: "",
        releaseFW: "",
      });
    } else {
      const pload = await api.getSiteDetails(
        this.state.dataOptions[idx].site.siteGuid
      );

      const gwInfo = this.state.dataOptions[idx];
      this.setState({
        createNew: false,
        selected: true,
        response: null,
        selectedGuid: gwInfo.gatewayGuid,
        selectedIdx: idx,
        name: gwInfo.name,
        telemetryPushInterval: gwInfo.telemetryPushInterval,
        macAddress: gwInfo.macAddress,
        totalDevices: gwInfo.devices.length,
        createdAt: gwInfo.createdAt,
        siteGuid: pload.data.siteGuid,
        errorState: "",
        releaseFW: await utils.getGatewayRelease(gwInfo.gatewayGuid, true),
      });
    }
  }

  buttonsToRender() {
    if (this.state.createNew) {
      return (
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.addData.bind(this)}
          >
            {this.props.t("gatewaysAdmin.createGateway")}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.updateData.bind(this)}
          >
            {this.props.t("gatewaysAdmin.updateGateway")}
          </Button>
          <Button
            className="mt-4"
            color="primary"
            type="button"
            onClick={this.deleteData.bind(this)}
          >
            {this.props.t("gatewaysAdmin.deleteGateway")}
          </Button>
        </div>
      );
    }
  }

  formToRender() {
    const siteOptions = this.state.sites.map((site) => ({
      label: site.name,
      value: site.siteGuid,
      name: site.name + "  [" + site.siteGuid + "]",
    }));

    const renderButtons = this.buttonsToRender();

    const formText = this.state.createNew
      ? this.props.t("gatewaysAdmin.enterGatewayInfo") +
        ' "' +
        this.props.t("gatewaysAdmin.createGateway") +
        '"'
      : this.props.t("gatewaysAdmin.editGatewayInfo");
    return (
      <Form role="form">
        <div className="text-center">
          {formText}
          <br />
          <br />
        </div>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.name")}
              name="name"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.name}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.macAddress")}
              name="macAddress"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.macAddress}
              disabled={!this.state.createNew}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.release")}
              name="releaseFW"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={
                this.state.createNew
                  ? ""
                  : this.state.releaseFW || "Not Available"
              }
              disabled={true}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.pushInterval")}
              name="telemetryPushInterval"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.telemetryPushInterval}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.createdAt")}
              name="createdAt"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.createdAt}
              disabled
            />
            <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
            <Input
              placeholder={this.props.t("gatewaysAdmin.totalDevices")}
              name="totalDevices"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.totalDevices}
              disabled
            />
          </InputGroup>
          <div className="mb-3 d-flex">
            <InputGroupText>{keys.ICON_SITE}</InputGroupText>
            <SelectSearch
              filterOptions={fuzzySearch}
              options={siteOptions}
              name="site"
              placeholder={this.props.t("gatewaysAdmin.addToSite") + "..."}
              value={this.state.siteGuid}
              search={true}
              onChange={this.onSiteSelected.bind(this)}
              autoComplete="on"
            />
          </div>
        </FormGroup>
        {renderButtons}
        <br />
        <br />
        {this.state.errorState === ""
          ? ""
          : this.props.t("gatewaysAdmin.thisIsTheResponse") +
            " (" +
            this.state.errorState +
            ")"}
        <br />
        <br />
        <div className="border">
          {this.state.response !== null
            ? JSON.stringify(this.state.response)
            : ""}
        </div>
      </Form>
    );
  }

  render() {
    var dataOpt = this.state.dataOptions;
    if (dataOpt.length === 0 || dataOpt[0].gatewayGuid !== "0") {
      dataOpt.unshift({
        gatewayGuid: "0",
        name: this.props.t("gatewaysAdmin.createNewGateway") + "...",
      });
    }

    const selectOptions = dataOpt.map((data, key) => ({
      label: data.name,
      value: key,
      name:
        data.gatewayGuid === "0"
          ? data.name
          : data.name + "  [" + data.gatewayGuid + "]",
    }));

    const renderForm = this.state.selected ? this.formToRender() : "";

    return (
      <>
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader>
                  <div className="w-50">
                    <SelectSearch
                      filterOptions={fuzzySearch}
                      options={selectOptions}
                      name="gateway"
                      placeholder={
                        this.props.t("gatewaysAdmin.selectGateway") +
                        "... (" +
                        this.props.t("gatewaysAdmin.total") +
                        ": " +
                        this.state.total +
                        ")"
                      }
                      search={true}
                      onChange={this.onDataSelected.bind(this)}
                      autoComplete="on"
                    />
                  </div>
                </CardHeader>
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {renderForm}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(GatewaysAdmin);
