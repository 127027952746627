import React, { useState, useEffect, useContext } from 'react';
import {Table, Badge, Card, CardHeader, CardBody, Button} from 'reactstrap';
import Pagination from "components/Pagination/index";
import { AuthorizationContext } from "contexts/AuthorizationContext";
import "./style.css";
import { TooltipButton } from 'components/Tooltip';

export function SubscriptionTable({ subscriptions, onSubscriptionClick, onAddClick, tableFull, t }) {
    const { hasWritePermission, activeOrganization } = useContext(AuthorizationContext);
    const [page, setPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState(null);
    const pageSize = 7;

    const formatDate = (dateString) => {
        return new Date(dateString).toISOString().split('T')[0];
    };

    const handleRowClick = (subscriptionGuid) => {
        setSelectedRow(subscriptionGuid);
        onSubscriptionClick(subscriptionGuid);
    };

    useEffect(() => {
        setSelectedRow(subscriptions.find(sub => sub.status.toLowerCase() === "active")?.subscriptionGuid || null);
    }, [subscriptions]);

    return (
        <Card className="border-0">
            <CardHeader className="subscription-tab-card">
                <h4 className="mb-0">
                    {t("subscription.subscriptionsOverview")}
                </h4>
                <Button id="addSubscription" className="mb-0" color="primary" size="sm" onClick={onAddClick} disabled={!hasWritePermission(activeOrganization?.guid)}>
                    {t("subscription.addSubscription")}
                </Button>
                <TooltipButton
                    target="addSubscription"
                    text={t("subscription.notAllowedToAddSubscription")}
                    style={{ background: '#FFF !important', color: '#000 !important', display: !hasWritePermission(activeOrganization?.guid) ? 'block' : 'none' }}
                />
            </CardHeader>
            <CardBody>
                <Table responsive>
                    <thead className="subscription-tab">
                    <tr>
                        <th>{t("subscription.subscription")}</th>
                        <th>{t("subscription.status")}</th>
                        {tableFull && <th>{t("subscription.organization")}</th>}
                        <th>{t("subscription.activationDate")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {subscriptions.sort((a, _) => a.status.toLowerCase() === "active" ? -1 : 1)
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((subscription) => (
                            <tr
                                key={subscription.subscriptionGuid}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: selectedRow === subscription.subscriptionGuid ? '#e9ecef' : 'inherit'
                                }}
                                onClick={() => handleRowClick(subscription.subscriptionGuid)}
                                className={`${
                                    subscription.status === "Expired" ? "sub-tab-text-expired" : "sub-tab-text"
                                } pt-0 pl-5 border-0`}
                            >
                                <td>
                                    {subscription.subscriptionName}
                                </td>
                                <td>
                                    <Badge
                                        className={subscription.status.toLowerCase() === "expired" || subscription.status.toLowerCase() === "cancelled" ? "badge-text-expired" : "badge-text"}
                                        color={
                                            subscription.status.toLowerCase() === "available"
                                                ? "primary"
                                                : subscription.status.toLowerCase() === "active"
                                                    ? "success"
                                                    : "secondary"
                                        }
                                        pill
                                    >
                                        {t(`subscription.${subscription.status.toLowerCase()}`)}
                                    </Badge>
                                </td>
                                {tableFull &&
                                    <td>
                                        {subscription.organizationName || "-"}
                                    </td>}
                                <td>
                                    {subscription.activeFrom ? formatDate(subscription.activeFrom) : "-"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    className={"mt-3 d-flex justify-content-center"}
                    currentPage={page}
                    totalPages={Math.ceil(!subscriptions ? 0 : subscriptions.length / pageSize)}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </CardBody>
        </Card>
    );
}