import React, { useEffect, useState, useContext } from 'react';
import {Modal, ModalBody, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import api from "../../services/backendService";
import utils from "../../utils";
import {useSelector} from "react-redux";
import { AuthorizationContext } from "contexts/AuthorizationContext";

export function ActivationModal({isOpen, setLoader, toggle, organizationGuid = null, refresh,t}) {
    const { hasWritePermission } = useContext(AuthorizationContext);
    const [activationCode, setActivationCode] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOrganizationGuid, setSelectedOrganizationGuid] = useState(organizationGuid);
    const organizations = useSelector((state) => state.customers.byId);

    useEffect(() => {
        if (isOpen) {
            setActivationCode("");
        }
    }, [isOpen]);

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleSelectOrganization = (organizationGuid) => {
        setSelectedOrganizationGuid(organizationGuid);
    };

    const assignLicensesToOrganizations = () => {
        if (!hasWritePermission(organizationGuid))
        {
            utils.debug("You don't have permission to add subscription to this organization", utils.MSG_TYPE_WARN);
            return;
        }

        setLoader(true);
        // Disable activation code
        api.disableActivationCode(activationCode)
            .then(async (response) => {
                // Assign licenses to organizations
                const {licenses} = response.data;
                const licenseGuids = licenses.map((l) => l.licenseGuid);
                await api.addLicenseToSubscription([selectedOrganizationGuid], licenseGuids);
                await refresh();
                toggle();
            })
            .catch((error) => {
                utils.debug(error, utils.MSG_TYPE_WARN);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="md">
            <ModalBody>
                <h3>{t("subscription.enterActivationCode")}</h3>
                <Input
                    className="mt-2"
                    type="text"
                    placeholder={t("subscription.activationCode")}
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}/>
                {organizationGuid === null && (
                    <div className="mt-4">
                        <h4>{t("subscription.selectAnOrganization")}</h4>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className=" mt-2 w-100">
                            <DropdownToggle caret>
                                {selectedOrganizationGuid ? organizations[selectedOrganizationGuid].name : t("subscription.selectAnOrganization")}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {Object.entries(organizations).map(([guid, org]) => (
                                    <DropdownItem key={guid} onClick={() => handleSelectOrganization(guid)}>
                                        {org.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                )}
                <Button
                    color="primary"
                    className="float-right mt-4"
                    onClick={assignLicensesToOrganizations}
                    disabled={activationCode.trim().length !== 36 || selectedOrganizationGuid === null}>
                    {t("subscription.addSubscription")}
                </Button>
            </ModalBody>
        </Modal>
    );
}