import React from "react";
import { Container, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setControllers } from "redux/actions/index";
import { createSelector } from "reselect";

import SelectSearch, { fuzzySearch } from "react-select-search";

import Loader from "components/Loader";
import api from "services/backendService";
import AlarmFastSetup from "components/Forms/AlarmFastSetup";
import DeviceThresholds from "components/DeviceThresholds";

import { withTranslation } from "react-i18next";

class EditAlarmConfiguration extends React.Component {
  state = {
    selectedSiteGuid: this.props.location.siteGuid || null,
    selectedGatewayGuid: this.props.location.gatewayGuid || null,
    loading: false,
    controllers: {},
  };

  async componentDidMount() {
    if (this.state.selectedGatewayGuid) {
      await this.refreshStore(this.state.selectedGatewayGuid);
    }
  }

  onSiteSelected(sGuid) {
    this.setState({
      selectedSiteGuid: sGuid,
      selectedGatewayGuid: null,
    });
  }

  // Loads and set controllers on the store for the site where the gateway is
  async onGatewaySelected(gwGuid) {
    await this.refreshStore(gwGuid);
  }

  async refreshStore(gwGuid, background = false) {
    if (!background) this.setState({ loading: true });
    const thNotify = (await api.getAllThresholdNotify()).data.data;
    this.setState({
      selectedGatewayGuid: gwGuid,
      notifications: thNotify,
    });
    const controllers = await api.getDevicesOnGateway(gwGuid);
    this.props.setControllers(controllers);
    if (!background)
      this.setState({ loading: false, controllers: controllers.byId });
    else this.setState({ controllers: controllers.byId });
  }

  toggleLoader = () => {
    this.setState({ loading: !this.state.loading });
  };

  render() {
    const deviceData = Object.values(this.state.controllers).filter(
      (dvc) => dvc.gateway.gatewayGuid === this.state.selectedGatewayGuid
    );

    let isThereAnyDefaultAlarm = false;
    for (const dvc of deviceData) {
      for (const point of Object.values(dvc.pointsById)) {
        if (point.vtype === "alarm") {
          isThereAnyDefaultAlarm = true;
          break;
        }
      }
      if (isThereAnyDefaultAlarm) break;
    }

    const noThresholds =
      deviceData.filter((dvc) => dvc.thresholds.length > 0).length === 0;

    const selectSiteOptions = Object.values(this.props.storeSites).map(
      (data) => ({
        label: data.name,
        value: data.siteGuid,
        name: this.props.t("alarmConfiguration.site") + ": " + data.name,
      })
    );

    let selectGatewayOptions = [];

    if (this.state.selectedSiteGuid !== null) {
      selectGatewayOptions = Object.values(this.props.storeGateways)
        .filter((gw) => gw.site.siteGuid === this.state.selectedSiteGuid)
        .map((data) => ({
          label: data.name,
          value: data.gatewayGuid,
          name: this.props.t("alarmConfiguration.gateway") + ": " + data.name,
        }));
    }

    const showFastSetup =
      isThereAnyDefaultAlarm &&
      noThresholds &&
      this.state.selectedGatewayGuid !== null;

    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <Container fluid>
          <Card className="mt--9">
            <CardHeader className="mt-2">
              {this.state.selectedGatewayGuid === null ? (
                <div className="h4 mb-3 font-weight-200">
                  {this.props.t("alarmConfiguration.selectTitle")}
                </div>
              ) : (
                <div className="h4 mb-3 font-weight-200">
                  {this.props.t(
                    "alarmConfiguration.showingAlertConfigurationFor"
                  )}
                </div>
              )}
              <Row>
                <Col xs="12" md="6" className="mb-4">
                  <SelectSearch
                    filterOptions={fuzzySearch}
                    options={selectSiteOptions}
                    name="site"
                    placeholder={this.props.t(
                      "alarmConfiguration.selectInstallation"
                    )}
                    search={true}
                    onChange={this.onSiteSelected.bind(this)}
                    autoComplete="on"
                    value={this.state.selectedSiteGuid}
                  />
                </Col>
                <Col xs="12" md="6">
                  <SelectSearch
                    filterOptions={fuzzySearch}
                    options={selectGatewayOptions}
                    name="gateway"
                    placeholder={this.props.t(
                      "alarmConfiguration.selectGateway"
                    )}
                    search={true}
                    onChange={this.onGatewaySelected.bind(this)}
                    autoComplete="on"
                    value={this.state.selectedGatewayGuid}
                    disabled={this.state.selectedSiteGuid === null}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {showFastSetup ? (
                <AlarmFastSetup
                  selectedSiteGuid={this.state.selectedSiteGuid}  
                  dvcs={deviceData}
                  updateStore={() =>
                    this.refreshStore(this.state.selectedGatewayGuid)
                  }
                  toggleLoader={this.toggleLoader}
                />
              ) : (
                deviceData.map((dvc) => {
                  const dnotify = this.state.notifications.filter(
                    (thNotify) => {
                      return dvc.deviceGuid === thNotify.threshold.deviceGuid;
                    }
                  );
                  return (
                    <DeviceThresholds 
                        selectedSiteGuid={this.state.selectedSiteGuid} 
                        key={dvc.deviceGuid}
                        guid={dvc.deviceGuid}
                        data={dvc}
                        notifications={dnotify}
                        defaultAvailable={isThereAnyDefaultAlarm}
                        updateStore={() =>
                          this.refreshStore(this.state.selectedGatewayGuid, true)
                      }
                    />
                  );
                })
              )}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

const getSites = createSelector(
  (state) => state.sites.byId,
  (sites) => {
    return sites;
  }
);

const getGateways = createSelector(
  (state) => state.gateways.byId,
  (gws) => {
    return gws;
  }
);

const mapStateToProps = (state) => {
  return {
    storeSites: getSites(state),
    storeGateways: getGateways(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setControllers,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(EditAlarmConfiguration)
);
