import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  ModalBody,
  ButtonGroup,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import ListDropdown from "components/ListDropdown";
import Loader from "components/Loader";
import { getGroupById, editUserRole } from "services/core-iam";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "components/Notify";
import keys from "configs/constants";
import Pagination from "components/Pagination/index";
import NewUsersModal from "./newUsersModal";
import "./style.css";
import SearchInput from "components/SearchInput";
import RemoveUsersModal from "./removeUsersModal";
import { useSelector } from "react-redux";
import {
  Permissions,
  useAuthorizationContext,
} from "contexts/AuthorizationContext";

const SMALL_SCREEN_WIDTH = 1200;

const AccessRights = (props) => {
  const context = useAuthorizationContext();
  const user = useSelector((state) => state.user);
  // const [organizations, setOrganizations] = useState(props.groups.filter(
  //     (group) => group.type === "Organization"
  // ).sort((a, b) => a.name.localeCompare(b.name)));
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeOrgId, setActiveOrgId] = useState(
    organizations.length === 1 ? organizations[0].id : null
  );
  const [activeOrgDetails, setActiveOrgDetails] = useState(null);
  const [entityFilter, setEntityFilter] = useState("");
  const [activeGroup, setActiveGroup] = useState(null);
  const [pendingChanges, setPendingChanges] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 8;
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [subGroupsTree, setSubGroupsTree] = useState([]);
  const [selectRemoveUsers, setSelectRemoveUsers] = useState([]);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);

  useEffect(() => {
    if (context.isSuperAdmin()) {
      const orgs = props.groups
        .filter((group) => group.type === "Organization")
        .sort((a, b) => a.name.localeCompare(b.name));
      setOrganizations(orgs);

      if (orgs.length > 0) {
        setActiveOrgId(orgs[0].id);
      }
    } else {
      const userOrg = props.groups.find(
        (group) => group.id === context.activeOrganization.id
      );
      setOrganizations(userOrg ? [userOrg] : []);
      setActiveOrgId(context.activeOrganization.id || null);
    }
  }, [context.isSuperAdmin, props.groups, context.activeOrganization]);

  useEffect(() => {
    loadGroup(activeOrgId);
  }, [activeOrgId]);

  const loadGroup = (groupId) => {
    if (!groupId) return;
    setIsLoading(true);
    getGroupById(groupId, true, false, true, true)
      .then((group) => {
        if (group.type === "Organization") setActiveOrgDetails(group);
        setActiveGroup(group);
        setSelectRemoveUsers([]);
        setPage(1);
        if (group.type !== "Organization")
          window.innerWidth < SMALL_SCREEN_WIDTH && setIsUserModalOpen(true);
      })
      .catch((err) =>
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent("danger", err.responseString),
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (activeOrgDetails && activeOrgDetails.subGroups) {
      const { subGroups } = activeOrgDetails;

      // Create a map of all groups
      const groupMap = subGroups.reduce((acc, group) => {
        acc[group.id] = { ...group, children: [] };
        return acc;
      }, {});

      // Build the tree structure
      const tree = [];
      subGroups.forEach((group) => {
        if (group.parentGroupId && groupMap[group.parentGroupId]) {
          groupMap[group.parentGroupId].children.push(groupMap[group.id]);
        } else {
          tree.push(groupMap[group.id]);
        }
      });

      // Sort groups alphabetically
      const sortGroups = (groups) => {
        return groups.sort((a, b) => a.name.localeCompare(b.name));
      };

      tree.forEach((group) => {
        group.children = sortGroups(group.children);
      });
      sortGroups(tree);

      setSubGroupsTree(tree);
    }
    setSelectRemoveUsers([]);
  }, [activeOrgDetails]);

  useEffect(() => {
    if (subGroupsTree.length > 0 && !activeGroup) {
      loadGroup(subGroupsTree[0].id);
    }
  }, [subGroupsTree]);

  const hasWritePermissionByGroupId = (groupId) => {
    return props.groups
      .find((g) => g.id === groupId)
      .role.permissions.includes(Permissions.GroupWrite);
  };

  const handleGroupChange = (groupId) => {
    setActiveGroup(null); // Reset the active group
    loadGroup(groupId); // Load the new group details
  };

  const filterGroupsRecursively = (groups, filter) => {
    return groups.reduce((filteredGroups, group) => {
      const matchesFilter = group.name
        .toLowerCase()
        .includes(filter.toLowerCase());

      let filteredChildren = [];
      if (group.children && group.children.length > 0) {
        filteredChildren = filterGroupsRecursively(group.children, filter);
      }

      if (matchesFilter || filteredChildren.length > 0) {
        filteredGroups.push({
          ...group,
          children: filteredChildren,
        });
      }

      return filteredGroups;
    }, []);
  };

  const renderGroupTree = (groups, level = 0) => {
    return groups.map((group) => {
      const isActive = activeGroup && activeGroup.id === group.id;

      return (
        <React.Fragment key={group.id}>
          <tr
            className={`pointer transition-all ${isActive ? "bg-info text-white" : ""}`}
            onClick={() => loadGroup(group.id)}
          >
            <td>
              <div
                className="group-row"
                style={{ paddingLeft: `${level * 20}px` }}
              >
                <div className="group-info">
                  <h4
                    className={`mb-0 transition-all text-overflow ${isActive ? "text-white" : ""}`}
                  >
                    {group.name}
                  </h4>
                  <small>{group.type}</small>
                </div>
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  const renderGroupList = () => {
    if (!activeOrgDetails || !activeOrgDetails.subGroups) return null;

    if (activeOrgDetails.subGroups.length === 0) {
      return <div>{props.t("accessRights.noGroupsAvailable")}</div>;
    }

    const filteredGroups = filterGroupsRecursively(subGroupsTree, entityFilter);

    return (
      <div className="ar-entity-list">
        <Table size="sm">
          <tbody>{renderGroupTree(filteredGroups)}</tbody>
        </Table>
      </div>
    );
  };

  const getCurrentRole = (groupId, userId) => {
    return !activeGroup
      ? null
      : activeGroup.members.find(
          (m) => m.userId === userId && activeGroup.id === groupId
        );
  };

  const onRoleChange = (groupId, userId, roleId) => {
    const currentRole = getCurrentRole(groupId, userId);
    const currentRoleId = currentRole ? currentRole.roleId : null;

    setPendingChanges((prevChanges) => {
      const newChanges = { ...prevChanges };
      if (roleId === currentRoleId) {
        delete newChanges[`${groupId}-${userId}`];
      } else {
        newChanges[`${groupId}-${userId}`] = { groupId, userId, roleId };
      }
      return newChanges;
    });
  };

  const hasActualChanges = Object.keys(pendingChanges).length > 0;

  const cancelChanges = () => {
    setPendingChanges({});
    activeGroup && loadGroup(activeGroup.id);
  };

  const saveChanges = () => {
    const changes = Object.values(pendingChanges).map((change) =>
      editUserRole(change.groupId, change.userId, change.roleId)
    );

    setIsLoading(true);
    Promise.all(changes)
      .then(() => {
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent("success", props.t("sidebar.changesApplied")),
        });
        return activeGroup && loadGroup(activeGroup.id);
      })
      .catch((err) => {
        // TODO: improve error message handling
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent("danger", err.responseString),
        });
      })
      .finally(() => {
        setPendingChanges({});
        setIsLoading(false);
      });
  };

  const renderGroupInfo = () => {
    if (!activeGroup) return null;

    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="mb-0">{activeGroup.name}</h3>
          <small>{activeGroup.type}</small>
        </div>
        <ButtonGroup>
          <Button
            color="secondary"
            onClick={cancelChanges}
            disabled={!hasActualChanges}
          >
            {keys.ICON_UNDO}
          </Button>
          <Button
            color="primary"
            onClick={saveChanges}
            disabled={!hasActualChanges}
          >
            {keys.ICON_SAVE}
          </Button>
          <Button
            color="primary"
            onClick={() => setIsAddUserModalOpen(true)}
            disabled={!hasWritePermissionByGroupId(activeGroup.id)}
          >
            {keys.ICON_ADD_USER}
          </Button>
          <Button
            color="danger"
            onClick={() => setIsRemoveUserModalOpen(true)}
            disabled={
              !hasWritePermissionByGroupId(activeGroup.id) ||
              !(selectRemoveUsers.length > 0)
            }
          >
            {keys.ICON_TRASH}
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  const getRoleName = (groupId, userId) => {
    const pendingChange = pendingChanges[`${groupId}-${userId}`];
    if (pendingChange) {
      const role = props.groups
        .find((x) => x.id === groupId)
        .roles.find((r) => r.id === pendingChange.roleId);
      return role ? role.name : null;
    }

    const currentRole = getCurrentRole(groupId, userId);
    if (!currentRole) return null;

    const role = props.groups
      .find((x) => x.id === groupId)
      .roles.find((r) => r.id === currentRole.roleId);
    return role ? role.name : null;
  };

  const renderGroupDetails = () => {
    if (!activeGroup) return null;

    const roles = props.groups.find((x) => x.id === activeGroup.id).roles;
    const members = activeGroup.members;

    const sortedMembers = [...members].sort((a, b) => {
      const lastNameComparison = a.lastName.localeCompare(b.lastName);
      if (lastNameComparison !== 0) {
        return lastNameComparison;
      }
      return a.firstName.localeCompare(b.firstName);
    });

    const isLastAdmin = (userEmail) => {
      const admins = activeGroup.members.filter(
        (member) =>
          member.roleName === "Admin" &&
          !selectRemoveUsers.includes(member.userId)
      );
      return admins.length === 1 && admins[0].email === userEmail;
    };

    const handleRowClick = (userId) => {
      setSelectRemoveUsers((prevSelected) => {
        if (prevSelected.includes(userId)) {
          return prevSelected.filter((id) => id !== userId);
        } else {
          return [...prevSelected, userId];
        }
      });
    };
    return (
      <div className="ar-entity-list nav-table ar-details-list">
        <Table size="sm">
          <thead>
            <tr>
              <th>{props.t("accessRights.user")}</th>
              <th>{props.t("accessRights.roleName")}</th>
            </tr>
          </thead>
          <tbody>
            {sortedMembers
              .slice((page - 1) * pageSize, page * pageSize)
              .map((member) => {
                const roleName = getRoleName(activeGroup.id, member.userId);
                const selected = selectRemoveUsers.includes(member.userId);

                return (
                  <tr
                    key={member.userId}
                    onClick={() => {
                      // TODO: warn user why they cannot perform this action
                      if (
                        context.isSuperAdmin() ||
                        (hasWritePermissionByGroupId(activeGroup.id) &&
                          !isLastAdmin(member.email))
                      ) {
                        handleRowClick(member.userId);
                      }
                    }}
                    style={{
                      backgroundColor: selected ? "#f0f0f0" : "transparent",
                    }}
                  >
                    <td>
                      <h4 className="mb-0">
                        {member.lastName} {member.firstName}
                      </h4>
                      <small>{member.email}</small>
                    </td>
                    <td>
                      {!roleName ? (
                        props.t("accessRights.noRole")
                      ) : (
                        <ListDropdown
                          toggleClass={`ar-role-dropdown font-weight-normal ${
                            pendingChanges[`${activeGroup.id}-${member.userId}`]
                              ? "bg-success text-white"
                              : ""
                          }`}
                          title={roleName}
                          listItems={roles.map((role) => ({
                            id: role.id,
                            text: role.name,
                            command: () =>
                              onRoleChange(
                                activeGroup.id,
                                member.userId,
                                role.id
                              ),
                          }))}
                          stopPropagation={true}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Pagination
          className={"mt-3 d-flex justify-content-center"}
          currentPage={page}
          totalPages={Math.ceil(!members ? 0 : members.length / pageSize)}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    );
  };

  const isUserRemoved = () => {
    const newMembersRemoved = activeGroup.members.filter((member) =>
      selectRemoveUsers.includes(member.userId)
    );
    return newMembersRemoved.some((member) => member.email === user.email);
  };

  const findParentGroupId = (tree, groupId) => {
    for (const group of tree) {
      if (group.id === groupId) {
        return group.parentGroupId;
      }
      const parentId = findParentGroupId(group.children, groupId);
      if (parentId !== undefined) {
        return parentId;
      }
    }
    return undefined;
  };

  const removeOrganization = (groupId) => {
    const newOrganizations = organizations.filter((org) => org.id !== groupId);
    setOrganizations(newOrganizations);
  };

  return (
    <Container fluid>
      {isLoading && <Loader />}
      <Card className="mt--9">
        <CardBody className="ar-container">
          <div className="ar-section ar-section-left">
            <div className="ar-section-top">
              <div className="d-flex justify-content-between align-items-center">
                {organizations.length === 1 ? (
                  <div className="selector-one-item">
                    {activeOrgDetails
                      ? activeOrgDetails.name
                      : props.t("accessRights.selectAnOrganization")}
                  </div>
                ) : (
                  <ListDropdown
                    className="w-100"
                    toggleClass="m-0"
                    title={
                      activeOrgDetails
                        ? activeOrgDetails.name
                        : props.t("accessRights.selectAnOrganization")
                    }
                    listItems={organizations.map((org) => ({
                      id: org.id,
                      text: org.name,
                      command: () => setActiveOrgId(org.id),
                    }))}
                  />
                )}
                <Button
                  color="primary"
                  className="ml-2"
                  disabled={activeGroup === activeOrgDetails}
                  onClick={() => handleGroupChange(activeOrgDetails.id)}
                >
                  {keys.ICON_EDIT}
                </Button>
              </div>
            </div>
            <SearchInput
              key="entity-filter"
              className="mt-2"
              placeholder={props.t("accessRights.searchGroups") + "..."}
              value={entityFilter}
              onChange={(filter) => setEntityFilter(filter)}
              toggleClassName="m-0 h-100"
            />
            <div className="ar-entity-list-container mt-3">
              {renderGroupList()}
            </div>
          </div>
          <div className="ar-section ar-section-right">
            <div className="ar-section-top">{renderGroupInfo()}</div>
            <div>{renderGroupDetails()}</div>
          </div>
        </CardBody>
        {activeGroup && (
          <NewUsersModal
            isOpen={isAddUserModalOpen}
            toggle={() => setIsAddUserModalOpen(false)}
            group={activeGroup}
            onSuccess={() => {
              activeGroup && loadGroup(activeGroup.id);
              notifyStore.addNotification({
                ...tempOptions,
                content: NotifyContent(
                  "success",
                  props.t("sidebar.changesApplied")
                ),
              });
            }}
            onFailure={(err) => {
              const notFound =
                err.data &&
                err.data.code &&
                err.data.code === "Groups.UserNotFound";
              const errMessage = notFound
                ? props.t("accessRights.notRegisteredUsersError")
                : err.responseString;
              notifyStore.addNotification({
                ...tempOptions,
                content: NotifyContent(
                  notFound ? "warning" : "danger",
                  errMessage
                ),
              });
            }}
            t={props.t}
          />
        )}
        {activeGroup && (
          <RemoveUsersModal
            isOpen={isRemoveUserModalOpen}
            toggle={() => setIsRemoveUserModalOpen(false)}
            onAbort={() => {
              setSelectRemoveUsers([]);
              setIsRemoveUserModalOpen(false);
            }}
            group={activeGroup}
            usersToRemove={activeGroup.members.filter((member) =>
              selectRemoveUsers.includes(member.userId)
            )}
            onSuccess={() => {
              if (isUserRemoved()) {
                const parentGroupId = findParentGroupId(
                  subGroupsTree,
                  activeGroup.id
                );
                if (!parentGroupId) {
                  removeOrganization(activeGroup.id);
                  setActiveGroup(null);
                  setActiveOrgId(organizations[0].id);
                } else {
                  loadGroup(activeOrgId);
                }
                loadGroup(parentGroupId);
              } else {
                loadGroup(activeGroup.id);
              }
              setSelectRemoveUsers([]);
              notifyStore.addNotification({
                ...tempOptions,
                content: NotifyContent(
                  "success",
                  props.t("sidebar.changesApplied")
                ),
              });
            }}
            onFailure={(err) =>
              notifyStore.addNotification({
                ...tempOptions,
                content: NotifyContent("danger", err.responseString),
              })
            }
            t={props.t}
          />
        )}
        <Modal
          isOpen={isUserModalOpen}
          toggle={() => setIsUserModalOpen(false)}
          centered
        >
          <ModalBody>
            <div className="mb-4">{renderGroupInfo()}</div>
            <div className="mt-4">{renderGroupDetails()}</div>
          </ModalBody>
        </Modal>
      </Card>
    </Container>
  );
};

export default withTranslation("common")(AccessRights);
