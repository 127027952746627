import React from 'react';
import {Modal, ModalBody, Button, ModalFooter, ModalHeader} from 'reactstrap';

export function ActivationSubscriptionModal({isOpen, toggle, onActivate, t}) {
    
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="md">
            <ModalBody>
                <ModalHeader>
          <span className="h2 modal-title">
            {t("subscription.activationSubscription")}
          </span>
                </ModalHeader>
                <dev>
                    <h4>{t("subscription.organizationAlreadyHasAnActiveSubscription")} </h4>
                    <h4>{t("subscription.areYouSureYouWantToReplaceIt")} </h4>
                </dev>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="float-right mt-4"
                    onClick={() => onActivate()}
                >
                    {t("subscription.confirm")}
                </Button>

                <Button
                    color="secondary"
                    className="float-right mt-4"
                    onClick={toggle}
                >
                    {t("subscription.cancel")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}