import React, { useEffect, useState, useRef } from "react";
import OpenStreetMap from "components/OpenStreetMap";
import { useTranslation } from "react-i18next";

const MapView = ({ sites, location }) => {
  const { t } = useTranslation("common");
  const mapRef = useRef(null);
  const [mapProps, setMapProps] = useState(null);

  const evaluateMapProps = () => {
    if (!sites || sites.length === 0) {
      return {
        centerPosition: [0, 0],
        zoom: 2,
      };
    }

    const bounds = sites.reduce((bounds, site) => {
      bounds.extend([site.latitude, site.longitude]);
      return bounds;
    }, new L.LatLngBounds());

    const centerPosition = bounds.getCenter();
    let zoom = 2;

    if (mapRef.current) {
      zoom = mapRef.current.getBoundsZoom(bounds);
    }

    return {
      centerPosition: [centerPosition.lat, centerPosition.lng],
      zoom: zoom,
    };
  };

  useEffect(() => {
    if (location?.site) {
      setMapProps({
        centerPosition: [location.site.latitude, location.site.longitude],
        zoom: 17,
      });
    } else {
      const newMapProps = evaluateMapProps();
      setMapProps(newMapProps);
    }
  }, [location, sites]);

  if (!mapProps || !mapProps.centerPosition || !mapProps.zoom) {
    return (
      <div className="text-center py-5">
        <i className="fas fa-spinner fa-spin fa-2x text-primary"></i>
        <p className="mt-3">{t("dashboard.loadingMap")}</p>
      </div>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <OpenStreetMap
        ref={mapRef}
        markers={sites}
        center={mapProps.centerPosition}
        zoom={mapProps.zoom}
      />
    </div>
  );
};

export default MapView;
