import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

export function SubscriptionDetails({
  subscription,
  onActivate,
  detailsFull,
  t,
}) {
  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split("T")[0];
  };

  const formatDuration = (days) => {
    if (!days) return "-";

    const units = [
      { limit: 28, divisor: 1, singular: "day", plural: "days" },
      { limit: 365, divisor: 30, singular: "month", plural: "months" },
      { limit: Infinity, divisor: 365, singular: "year", plural: "years" },
    ];

    const unit = units.find((u) => days < u.limit);
    const value = Math.max(1, Math.floor(days / unit.divisor));

    return `${value} ${t(`license.${value === 1 ? unit.singular : unit.plural}`)}`;
  };

  const getExpirationDate = (subscription) => {
    const activeFrom = new Date(subscription.activeFrom);
    const duration = subscription.duration;

    if (!activeFrom || !duration) return "-";
    const expirationDate = new Date(activeFrom);
    expirationDate.setDate(expirationDate.getDate() + duration);
    return formatDate(expirationDate);
  };

  return (
    <Card className="border-0">
      <CardHeader className="sub-details-container">
        <h4>{t("subscription.subscriptionDetails")}</h4>
      </CardHeader>
      {subscription && (
        <CardBody
          className={
            subscription.status === "Expired"
              ? "sub-tab-text-expired"
              : "sub-tab-text"
          }
        >
          <div className="sub-details-name">
            {subscription.subscriptionName}
          </div>
          <ListGroup flush>
            <ListGroupItem className="sub-details-item">
              {t("subscription.status")}
              <Badge
                className={
                  subscription.status.toLowerCase() === "expired" ||
                  subscription.status.toLowerCase() === "cancelled"
                    ? "badge-text-expired-details"
                    : "badge-text-details"
                }
                color={
                  subscription.status.toLowerCase() === "available"
                    ? "primary"
                    : subscription.status.toLowerCase() === "active"
                      ? "success"
                      : "secondary"
                }
                pill
              >
                {t(`subscription.${subscription.status.toLowerCase()}`)}
              </Badge>
            </ListGroupItem>
            {detailsFull && (
              <ListGroupItem className="sub-details-item">
                {t("subscription.organization")}
                <span className="text-primary">
                  {subscription.organizationName || "-"}
                </span>
              </ListGroupItem>
            )}
            <ListGroupItem className="sub-details-item">
              {t("subscription.activationDate")}
              <span>
                {subscription.activeFrom
                  ? formatDate(subscription.activeFrom)
                  : "-"}
              </span>
            </ListGroupItem>
            <ListGroupItem className="sub-details-item">
              {t("subscription.duration")}
              <span>{formatDuration(subscription.duration)}</span>
            </ListGroupItem>
            <ListGroupItem className="sub-details-item">
              {t("license.expiresOn")}
              <span>{getExpirationDate(subscription)}</span>
            </ListGroupItem>
          </ListGroup>
          <div className="features-section">
            <h3>{t("subscription.features")}</h3>
            <div className="features-container">
              <ListGroup flush className="rounded">
                {Object.entries(subscription.features).map(
                  ([key, value], index) => {
                    let displayValue = value;
                    if (typeof value === "boolean") {
                      displayValue = value ? (
                        <span
                          className="text-success"
                          style={{ fontSize: "1rem" }}
                        >
                          ✓
                        </span>
                      ) : (
                        <span
                          className="text-danger"
                          style={{ fontSize: "1rem" }}
                        >
                          ✗
                        </span>
                      );
                    } else if (typeof value === "number" && value === -1) {
                      displayValue = t("subscription.unlimited");
                    }

                    return (
                      <ListGroupItem key={index} className="feature-items">
                        <span>
                          <strong>{t(`subscription.${key}`)}</strong>
                        </span>
                        <span>{displayValue}</span>
                      </ListGroupItem>
                    );
                  }
                )}
              </ListGroup>
            </div>
          </div>
          {subscription.status.toLowerCase() === "available" && onActivate && (
            <Button
              color="primary"
              block
              onClick={() => onActivate(subscription)}
              size="sm"
            >
              {t("subscription.activateSubscription")}
            </Button>
          )}
        </CardBody>
      )}
    </Card>
  );
}
