import React, { useState } from "react";
import { Table, ButtonGroup, Button, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { TooltipButton } from "components/Tooltip";
import Reports from "views/Reports";
import Pagination from "components/Pagination";
import { useTranslation } from "react-i18next";
import utils from "../../utils";

const SearchFilterControls = ({
  searchTerm,
  setSearchTerm,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className="list-controls">
      <div className="search-box">
        <i className="fas fa-search search-icon"></i>
        <input
          type="text"
          placeholder={t("dashboard.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="filter-buttons">
        <ButtonGroup>
          <Button
            color="success"
            active={filter === "all"}
            onClick={() => setFilter("all")}
          >
            <i className="fas fa-globe-americas mr-1"></i>{" "}
            {t("dashboard.allFilter")}
          </Button>
          <Button
            color="danger"
            active={filter === "issues"}
            onClick={() => setFilter("issues")}
          >
            <i className="fas fa-exclamation-triangle mr-1"></i>{" "}
            {t("dashboard.issuesFilter")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

const ActionButtons = ({ site, toggleReportModal, isCompact = false }) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={`d-flex justify-content-center gap-2 ${isCompact ? "align-items-center" : ""}`}
    >
      <Button
        id={`report${site.siteGuid}`}
        color="info"
        size={isCompact ? "sm" : "md"}
        onClick={() => toggleReportModal(site, true)}
      >
        <i className="fas fa-file-alt"></i>{" "}
        {!isCompact && <span>{t("plant.report")}</span>}
      </Button>
      <TooltipButton
        target={`report${site.siteGuid}`}
        text={t("plant.exportFoodQualityReport")}
      />

      <Button
        id={`export${site.siteGuid}`}
        color="info"
        size={isCompact ? "sm" : "md"}
        onClick={() => toggleReportModal(site, false)}
      >
        <i className="fas fa-download"></i>{" "}
        {!isCompact && <span>{t("plant.data")}</span>}
      </Button>
      <TooltipButton
        target={`export${site.siteGuid}`}
        text={t("plant.exportDeviceMeasures")}
      />
    </div>
  );
};

const SiteLink = ({ site, className, children }) => (
  <Link
    to={{
      pathname: "/std/dvcs",
      site: {
        siteGuid: site.siteGuid,
        name: site.name,
        city: site.city,
        country: site.country,
        latitude: site.latitude,
        longitude: site.longitude,
      },
    }}
    className={className}
  >
    {children}
  </Link>
);

const CompactSiteList = ({ filteredSites, toggleReportModal }) => {
  return (
    <div className="compact-list">
      {filteredSites.map((site) => (
        <div key={site.siteGuid} className="compact-site-row p-3 border-bottom">
          <div className="d-flex">
            <div className="site-info">
              <div className="site-header">
                <SiteLink
                  site={site}
                  className="font-weight-bold d-flex align-items-center"
                >
                  <StatusIndicator site={site} />
                  <span>{site.name}</span>
                </SiteLink>
              </div>
              <div className="site-location mt-1">
                <i className="fas fa-map-marker-alt mx-1"></i>
                <span className="text-muted small">{site.city || "N/A"}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <ActionButtons
                site={site}
                toggleReportModal={toggleReportModal}
                isCompact={true}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const SiteTable = ({ filteredSites, page, pageSize, toggleReportModal }) => {
  const { t } = useTranslation("common");

  return (
    <div className="table-responsive">
      <Table className="align-items-center table-flush sites-table">
        <thead className="thead-light">
          <tr>
            <th style={{ width: "25%" }}>
              <i className="fas fa-city mr-2"></i> {t("dashboard.site")}
            </th>
            <th style={{ width: "25%" }}>
              <i className="fas fa-map-marker-alt mr-2"></i>{" "}
              {t("dashboard.location")}
            </th>
            <th style={{ width: "25%" }} className="text-center">
              <i className="fas fa-signal mr-2"></i> {t("dashboard.status")}
            </th>
            <th style={{ width: "25%" }} className="text-center">
              <i className="fas fa-play mr-2"></i> {t("dashboard.actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredSites
            .slice((page - 1) * pageSize, page * pageSize)
            .map((site) => (
              <tr key={site.siteGuid}>
                <td>
                  <SiteLink site={site} className="font-weight-bold text-reset">
                    {site.name}
                  </SiteLink>
                </td>
                <td className="text-muted">{site.city || "N/A"}</td>
                <td className="text-center">
                  <StatusIndicator site={site} />
                </td>
                <td>
                  <ActionButtons
                    site={site}
                    toggleReportModal={toggleReportModal}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const StatusIndicator = ({ site }) => (
  <div className="status-indicator">
    {site.offlineGateways.length > 0 || site.alarms.length > 0 ? (
      <i className="fas fa-exclamation-circle text-danger"></i>
    ) : (
      <i className="fas fa-check-circle text-success"></i>
    )}
  </div>
);

const ListView = ({ sites, gateways }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isReport, setIsReport] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null);
  const [gatewaysList, setGatewaysList] = useState([]);
  const [deviceByGw, setDeviceByGw] = useState({});
  const pageSize = 5;

  const { t } = useTranslation("common");

  const filteredSites = sites.filter((site) => {
    const matchesSearch =
      site.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (site.city || "").toLowerCase().includes(searchTerm.toLowerCase());
    const hasIssues = site.offlineGateways.length > 0 || site.alarms.length > 0;
    return filter === "all" ? matchesSearch : matchesSearch && hasIssues;
  });

  const toggleReportModal = (site, isReportType = true) => {
    const gatewaysList = Object.values(
      utils.getGatewaysForSite(site.siteGuid, gateways),
    );

    const deviceByGw = gatewaysList.reduce((acc, gw) => {
      acc[gw.name] = gw.devices;
      return acc;
    }, {});

    setSelectedSite(site);
    setIsReport(isReportType);
    setShowModal(!showModal);
    setGatewaysList(gatewaysList);
    setDeviceByGw(deviceByGw);
  };

  return (
    <div className="sites-list-container">
      <SearchFilterControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
        setFilter={setFilter}
      />

      <div className="d-none d-lg-block">
        <SiteTable
          filteredSites={filteredSites}
          page={page}
          pageSize={pageSize}
          toggleReportModal={toggleReportModal}
        />
      </div>

      <div className="d-lg-none">
        <CompactSiteList
          filteredSites={filteredSites.slice(
            (page - 1) * pageSize,
            page * pageSize,
          )}
          toggleReportModal={toggleReportModal}
        />
      </div>

      <div className="d-flex justify-content-center mt-4">
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(filteredSites.length / pageSize)}
          onPageChange={setPage}
        />
      </div>

      <Modal
        centered
        isOpen={showModal}
        toggle={() => toggleReportModal(selectedSite, isReport)}
      >
        <Reports
          site={selectedSite}
          gateways={gatewaysList}
          deviceByGw={deviceByGw}
          toggle={() => toggleReportModal(selectedSite, isReport)}
          isReport={isReport}
          t={t}
        />
      </Modal>
    </div>
  );
};

export default ListView;
