import React, { Component } from "react";
import { ModalBody, Button, Input } from "reactstrap";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../../components/Notify";
import ListDropdown from "../../components/ListDropdown";
import api from "../../services/backendService";
import Loader from "../../components/Loader";
import utils from "../../utils";
import ReportThresholds from "./ReportThresholds";
import "./reports.css";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";

const MAX_DEVICES = 5;
const DAY_MINUTES = 60 * 24;

class OnDemandReports extends Component {
  static contextType = AuthorizationContext;
  constructor(props) {
    super(props);

    let startReportDate = new Date();
    startReportDate.setDate(startReportDate.getDate() - 1);

    // Set device list for data measures
    let devices = {};

    for (const gateway in this.props.deviceByGw) {
      const devs = this.props.deviceByGw[gateway];

      for (const dev of devs) {
        devices = {
          ...devices,
          [dev.deviceGuid]: false,
        };
      }
    }

    this.state = {
      title: "Report - " + this.props.site.name,
      showReport: false,
      reportDate: new Date().toISOString().split("T")[0],
      startReportDate: new Date().toISOString().split("T")[0],
      listValue: 0, // 0: Today, 1: Yesterday, 2: Custom
      outputValue: 0, // 0: pdf/xls, 1: xls/zip
      foodQuality: 0, // 0: HACCP, 1: FQR
      data: null,
      isLoading: false,
      warningUp: "3.0",
      alarmUp: "5.0",
      warningDown: "-3.0",
      alarmDown: "-5.0",
      thresholdsError: false,
      devices: devices,
    };
  }

  // Download API result (data measures or food quality report)
  getAPIResult = (api, outputType) => {
    this.id = api.id;

    api.execute
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;

        let fileName =
          this.state.title +
          "." +
          outputType +
          (outputType === "xls" ? "x" : "");

        if (this.state.title === "" || this.state.title === null) {
          let headerLine = response.headers["content-disposition"];
          let startFileNameIndex = headerLine.indexOf('"') + 1;
          let endFileNameIndex = headerLine.lastIndexOf('"');
          fileName = headerLine.substring(startFileNameIndex, endFileNameIndex);
        }

        link.setAttribute("download", fileName);
        link.className = "d-none";
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
        this.props.toggle();
      })
      .catch(async (err) => {
        let errMessage = (await err.data.text()).replace(/"/g, "");

        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent("danger", null, errMessage),
        });

        this.setState({ isLoading: false });
        this.props.toggle();
      });
  };

  // Call API to generate reports (measures or FQR)
  loadData = async () => {
    this.setState({ isLoading: true });

    if (this.props.isReport) {
      // Food Quality Report
      let outputType = this.state.outputValue === 0 ? "pdf" : "xls";
      api
        .getDeviceReport(
          this.props.site.siteGuid,
          this.state.reportDate,
          this.state.alarmUp,
          this.state.warningUp,
          this.state.warningDown,
          this.state.alarmDown,
          this.state.foodQuality,
          outputType
        )
        .then((api) => {
          this.getAPIResult(api, outputType);
        });
    } else {
      // Data Measures
      let devicesGuid = Object.keys(this.state.devices).filter((deviceGuid) => {
        if (this.state.devices[deviceGuid]) {
          return true;
        }
        return false;
      });

      // If no devices have been selected, use all
      if (devicesGuid.length === 0) {
        devicesGuid = Object.keys(this.state.devices);
      }

      if (devicesGuid.length === 0) return null;

      const dateTo = new Date(this.state.reportDate + "T00:00:00");
      dateTo.setDate(dateTo.getDate() + 1);

      const dateFrom = this.props.isReport
        ? null
        : new Date(this.state.startReportDate + "T00:00:00");

      let outputType = this.state.outputValue === 0 ? "xls" : "zip";

      api
        .getDeviceMeasure(
          devicesGuid,
          this.props.site.siteGuid,
          dateFrom,
          dateTo,
          outputType,
          this.context.activeOrganization?.guid
        )
        .then((api) => {
          this.getAPIResult(api, outputType);
        });
    }
  };

  render() {
    let periodList = [];
    let outputList = [];
    let inputError = false;

    const subscription = this.context.activeOrganization?.subscription;
    const maxDaysBackReport = (this.context. isSuperAdmin()|| !subscription) ? 365 : subscription.features.dataRetentionInDays;

    // Common selections for both types of reports
    periodList.push({
      text: this.props.t("report.today"),
      command: () => {
        this.setState({
          listValue: 0,
          startReportDate: new Date().toISOString().split("T")[0],
          reportDate: new Date().toISOString().split("T")[0],
        });
      },
    });

    periodList.push({
      text: this.props.t("report.yesterday"),
      command: () => {
        let yesterday = utils.setOffsetDate();

        this.setState({
          listValue: 1,
          startReportDate: yesterday.toISOString().split("T")[0],
          reportDate: yesterday.toISOString().split("T")[0],
        });
      },
    });

    // Add custom selections
    if (!this.props.isReport) {
      // Period list
      periodList.push({
        text: this.props.t("report.last3d"),
        command: () => {
          let endPeriod = utils.setOffsetDate();
          let startPeriod = utils.setOffsetDate(endPeriod, DAY_MINUTES * 2);

          this.setState({
            listValue: 2,
            startReportDate: startPeriod.toISOString().split("T")[0],
            reportDate: endPeriod.toISOString().split("T")[0],
          });
        },
      });

      periodList.push({
        text: this.props.t("report.last7d"),
        command: () => {
          let endPeriod = utils.setOffsetDate();
          let startPeriod = utils.setOffsetDate(endPeriod, DAY_MINUTES * 6);

          this.setState({
            listValue: 3,
            startReportDate: startPeriod.toISOString().split("T")[0],
            reportDate: endPeriod.toISOString().split("T")[0],
          });
        },
      });

      periodList.push({
        text: this.props.t("report.last31d"),
        command: () => {
          let endPeriod = utils.setOffsetDate();
          let startPeriod = utils.setOffsetDate(endPeriod, DAY_MINUTES * 30);

          this.setState({
            listValue: 4,
            startReportDate: startPeriod.toISOString().split("T")[0],
            reportDate: endPeriod.toISOString().split("T")[0],
          });
        },
      });

      periodList.push({
        text: this.props.t("report.last3m"),
        command: () => {
          let endPeriod = utils.setOffsetDate();
          let startPeriod = utils.setOffsetDate(endPeriod, DAY_MINUTES * 91);

          this.setState({
            listValue: 5,
            startReportDate: startPeriod.toISOString().split("T")[0],
            reportDate: endPeriod.toISOString().split("T")[0],
          });
        },
      });

      periodList.push({
        text: this.props.t("report.customInterval"),
        command: () => {
          this.setState({ listValue: 6 });
        },
      });

      // Output type list
      outputList.push({
        text: "Excel",
        command: () => {
          this.setState({ outputValue: 0 });
        },
      });

      outputList.push({
        text: "Zip",
        command: () => {
          this.setState({ outputValue: 1 });
        },
      });
    } else {
      // Period list
      periodList.push({
        text: this.props.t("report.custom"),
        command: () => {
          this.setState({ listValue: 2 });
        },
      });

      // Output type list
      outputList.push({
        text: "PDF",
        command: () => {
          this.setState({ outputValue: 0 });
        },
      });

      outputList.push({
        text: "Excel",
        command: () => {
          this.setState({ outputValue: 1 });
        },
      });
    }

    const reportTypeList = [
      {
        text: "HACCP",
        command: () => {
          this.setState({ foodQuality: 0 });
        },
      },
      {
        text: "FQR",
        command: () => {
          this.setState({ foodQuality: 1 });
        },
      },
    ];

    let minStartReportDate = new Date();
    minStartReportDate.setDate(new Date().getDate() - (maxDaysBackReport - 1));

    // Devices for Measure report
    let devicesList = [];

    for (const gwName in this.props.deviceByGw) {
      const devices = this.props.deviceByGw[gwName];
      devicesList.push({
        text: gwName,
        header: true,
      });

      for (const device of devices) {
        devicesList.push({
          // Show friendly or model
          text:
            device.friendlyName === null || device.friendlyName === ""
              ? utils.getModelFromMFVE(device.model)
              : device.friendlyName,
          checkbox: true,
          checkboxStatus: this.state.devices[device.deviceGuid],
          // Disable other devices when the maximum number of selected devices is reached
          disabled:
            Object.keys(this.state.devices).filter(
              (deviceGuid) => this.state.devices[deviceGuid]
            ).length >= MAX_DEVICES && !this.state.devices[device.deviceGuid],
          command: () => {
            // Enable / Disable device
            let { devices } = this.state;
            devices[device.deviceGuid] = !devices[device.deviceGuid];
            this.setState({ devices });
          },
        });
      }
    }

    let nSelectedDevices = Object.keys(this.state.devices).filter(
      (deviceGuid) => this.state.devices[deviceGuid]
    ).length;

    inputError =
      inputError ||
      (nSelectedDevices === 0 &&
        Object.keys(this.state.devices).length > MAX_DEVICES);

    const isButtonDisabled = this.state.thresholdsError || (inputError && !this.props.isReport);

    return (
      <ModalBody>
        <div className="report-conf">
          <span className="report-label">
            {this.props.t("report.title") + ":"}
          </span>
          <Input
            name="name"
            className="m-2"
            placeholder="Title"
            value={this.state.title}
            onChange={(e) => this.onInputChange(e)}
          />
          <div
            className={`${
              this.props.isReport ? "report-info-single" : "report-info"
            }`}
          >
            <span className="report-label label-0 ml-3">
              {this.props.t("report.outputType") + ":"}
            </span>
            {this.props.isReport ? null : (
              <span className="report-label label-1">
                {this.props.t("report.controllers") +
                  " (" +
                  this.props.t("report.max5").toLowerCase() +
                  ")"}
                :
              </span>
            )}
            <span className="report-output">
              <ListDropdown
                className={`${
                  this.props.isReport ? "w-100" : "report-output-dropdown"
                }`}
                toggleClass="report-output-toggle"
                title={
                  this.props.isReport
                    ? this.state.outputValue === 0
                      ? "PDF"
                      : this.state.outputValue === 1
                        ? "Excel"
                        : null
                    : this.state.outputValue === 0
                      ? "Excel"
                      : this.state.outputValue === 1
                        ? "Zip"
                        : null
                }
                listItems={outputList}
              />
              {this.props.isReport && 
                <ListDropdown
                  className={`${
                    this.props.isReport ? "w-100" : "report-output-dropdown"
                  }`}
                  toggleClass="report-output-toggle"
                  title={this.state.foodQuality === 1 ? "FQR" : "HACCP"}
                  listItems={reportTypeList}
                  disabled={!this.props.fqrEnabled}
                />}
            </span>

            {this.props.isReport ? null : (
              <span className="report-controllers">
                <ListDropdown
                  className="report-controllers-dropdown"
                  toggleClass={`report-controllers-toggle ${
                    nSelectedDevices === 0 &&
                    Object.keys(this.state.devices).length > MAX_DEVICES
                      ? "bg-warning text-white"
                      : ""
                  }`}
                  title={
                    this.props.t("report.controllers") +
                    " (" +
                    (nSelectedDevices > 0
                      ? nSelectedDevices
                      : this.props.t("alarmLog.all").toLowerCase()) +
                    ")"
                  }
                  listItems={devicesList}
                />
              </span>
            )}
          </div>
          <ReportThresholds 
            alarmUp={this.state.alarmUp}
            warningUp={this.state.warningUp}
            warningDown={this.state.warningDown}
            alarmDown={this.state.alarmDown}
            foodQuality={this.state.foodQuality}
            isReport={this.props.isReport}
            onInputChange={(e) => {
              for(const key in e) {
                this.setState({ [key]: e[key] });
              }
            }}
            t={this.props.t}
          />
          <span className="report-label mt-2">
            {this.props.isReport
              ? this.props.t("report.date") + ":"
              : this.props.t("report.interval") +
                " (" +
                this.props.t("report.max3months").toLowerCase() +
                "):"}
          </span>
          <span
            className={`${
              !this.props.isReport ? "report-dates-measures" : "report-dates"
            }`}
          >
            <ListDropdown
              className="report-dates-dropdown"
              title={
                this.props.isReport
                  ? this.state.listValue === 0
                    ? this.props.t("report.today")
                    : this.state.listValue === 1
                      ? this.props.t("report.yesterday")
                      : this.props.t("report.custom")
                  : this.state.listValue === 0
                    ? this.props.t("report.today")
                    : this.state.listValue === 1
                      ? this.props.t("report.yesterday")
                      : this.state.listValue === 2
                        ? this.props.t("report.last3d")
                        : this.state.listValue === 3
                          ? this.props.t("report.last7d")
                          : this.state.listValue === 4
                            ? this.props.t("report.last31d")
                            : this.state.listValue === 5
                              ? this.props.t("report.last3m")
                              : this.props.t("report.customInterval")
              }
              listItems={periodList}
            />
            <input
              className="input-date date-from"
              type="date"
              disabled={
                (this.state.listValue !== 2 && this.props.isReport) ||
                (this.state.listValue !== 6 && !this.props.isReport)
              }
              hidden={this.props.isReport}
              value={this.state.startReportDate}
              min={this.context.isSuperAdmin()? null: minStartReportDate.toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>{
                let newStartDate = new Date(e.target.value);
                let currentReportDate = new Date(this.state.reportDate);
                // If the start date exceeds the end date, we update the end date
                if (newStartDate > currentReportDate) {
                  this.setState({ reportDate: e.target.value });
                }
                // Check the interval does not exceed 92 days
                let maxAllowedEndDate = new Date(newStartDate);
                maxAllowedEndDate.setDate(maxAllowedEndDate.getDate() + 92);
                if (currentReportDate > maxAllowedEndDate) {
                  this.setState({ reportDate: maxAllowedEndDate.toISOString().split("T")[0] });
                }

                this.setState({ startReportDate: e.target.value });
              }}
            />
            <input
              className="input-date date-to"
              type="date"
              disabled={
                (this.state.listValue !== 2 && this.props.isReport) ||
                (this.state.listValue !== 6 && !this.props.isReport)
              }
              value={this.state.reportDate}
              min={this.context.isSuperAdmin()? null: minStartReportDate.toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>{
                let newReportDate = new Date(e.target.value);
                let currentStartDate = new Date(this.state.startReportDate);
                // If the end date is less than the start date, we update the start date
                if (newReportDate < currentStartDate) {
                  this.setState({ startReportDate: e.target.value });
                }
                // Check the interval does not exceed 92 days
                let minAllowedStartDate = new Date(newReportDate);
                minAllowedStartDate.setDate(minAllowedStartDate.getDate() - 92);
                if (currentStartDate < minAllowedStartDate) {
                  this.setState({ startReportDate: minAllowedStartDate.toISOString().split("T")[0] });
                }
                
                this.setState({ reportDate: e.target.value });
              }}
            />
          </span>

          <Button
            className={`${!this.props.isReport ? "mt-2" : ""}`}
            disabled={isButtonDisabled}
            color="primary"
            onClick={() => {
              this.loadData();
            }}
          >
            {this.props.isReport
              ? this.props.t("report.createReport")
              : this.props.t("report.exportData")}
          </Button>
          {this.state.isLoading ? <Loader /> : null}
        </div>
      </ModalBody>
    );
  }
}

export default OnDemandReports;
