import React, { createContext, useState, useContext, useMemo } from "react";
import { store as reduxStore } from "../index";
import { getSelfGroups } from "../services/core-iam";
import api from "../services/backendService";
import utils from "../utils";

export const AuthorizationContext = createContext(null);

export const AuthorizationContextProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const activeOrganization = useMemo(
    () => organizations.find((org) => org.isActive) || null,
    [organizations],
  );
  
  const isSuperAdmin = () => reduxStore.getState().user.role === "Admin";

  const activeOrgLocalStorageKey = "ao";
  
  const initialize = async () => {
    try {
      const groups = await getSelfGroups();
      setGroups(groups);

      if (!isSuperAdmin()) {
        const response = await api.getCustomersInitialState();
        const customers = Object.values(response.byId);

        let activeOrgGuid = localStorage.getItem(activeOrgLocalStorageKey);
        if (!activeOrgGuid && customers.length > 0) {
          activeOrgGuid = customers[0].customerGuid;
          localStorage.setItem(activeOrgLocalStorageKey, activeOrgGuid);
        }

        const orgs = await Promise.all(
            customers.map(async (customer) => {
              const isActive = customer.customerGuid === activeOrgGuid;
              const orgData = {
                guid: customer.customerGuid,
                name: customer.name,
                id: customer.groupId,
                isActive,
                subscription: null,
              };

              if (isActive) {
                try {
                  const subscriptionResponse = await api.getCustomerSubscription(customer.customerGuid);
                  orgData.subscription = subscriptionResponse.data;
                } catch (error) {
                  utils.debug(error, utils.MSG_TYPE_ERR);
                }
              }

              return orgData;
            })
        );

        setOrganizations(orgs);
      }
    } catch (error) {
      utils.debug(error, utils.MSG_TYPE_ERR);
      throw error;
    }
  };
  
  const setActiveOrganization = async (orgGuid) => {
    setOrganizations((prevOrgs) =>
      prevOrgs.map((org) => ({
        ...org,
        isActive: org.guid === orgGuid,
      })),
    );
    await updateOrganizationSubscription(orgGuid);
    localStorage.setItem(activeOrgLocalStorageKey, orgGuid);
  };

  const setOrganizationSubscription = async (orgGuid, subscriptionData) => {
    setOrganizations((prevOrgs) =>
      prevOrgs.map((org) => ({
        ...org,
        subscription:
          org.guid === orgGuid ? subscriptionData : org.subscription,
      })),
    );
  };

  const updateOrganizationSubscription = async (orgGuid) => {
    if (!isSuperAdmin()) {
      try {
        const subscriptionResponse = await api.getCustomerSubscription(orgGuid);
        await setOrganizationSubscription(orgGuid, subscriptionResponse.data);
        return subscriptionResponse.data;
      } catch (error) {
        utils.debug(error, utils.MSG_TYPE_ERR);
        throw error;
      }
    }
    return null;
  };

  const getGroupByGuid = (groupGuid) => {
    const { customers, sites, gateways } = reduxStore.getState();

    if (customers.byId[groupGuid]) {
      return customers.byId[groupGuid];
    }

    if (sites.byId[groupGuid]) {
      return sites.byId[groupGuid];
    }

    if (gateways.byId[groupGuid]) {
      return gateways.byId[groupGuid];
    }

    return null;
  };

  const isMemberOfSomeOrganization = () =>
    isSuperAdmin() || groups.some((group) => group.type === "Organization");

  const canRegisterNewGateway = () => isMemberOfSomeOrganization();

  const hasPermission = (groupGuid, permission) => {
    if (isSuperAdmin()) {
      return true;
    }

    const groupData = getGroupByGuid(groupGuid);

    if (!groupData) {
      return false;
    }

    const group = groups.find((g) => g.id === groupData.groupId);
    return !group ? false : group.role.permissions.includes(permission);
  };

  const getSitesWithWritePermission = () => {
    const groupIds = groups
      .filter(
        (group) =>
          group.type === "Site" &&
          (isSuperAdmin() ||
            group.role.permissions.includes(Permissions.GroupWrite)),
      )
      .map((group) => group.id);
    const { sites } = reduxStore.getState();
    return Object.values(sites.byId).filter((site) =>
      groupIds.includes(site.groupId),
    );
  };

  const hasWritePermission = (groupGuid) =>
    hasPermission(groupGuid, Permissions.GroupWrite);

  const getSubscriptionFeatureValue = (featureName) => {
    if (!activeOrganization?.subscription?.features) {
      return null
    }
    return activeOrganization.subscription.features[featureName] || null
  }

  return (
    <AuthorizationContext.Provider
      value={{
        initialize,
        organizations,
        setOrganizations,
        setActiveOrganization,
        activeOrganization,
        isSuperAdmin,
        setOrganizationSubscription,
        updateOrganizationSubscription,
        groups,
        setGroups,
        isMemberOfSomeOrganization,
        canRegisterNewGateway,
        hasPermission,
        hasWritePermission,
        getSitesWithWritePermission,
        getSubscriptionFeatureValue
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export function useAuthorizationContext() {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      "useAuthorizationContext must be used within a AuthorizationContextProvider",
    );
  }
  return context;
}

export class Permissions {
  static GroupRead = "group.read";
  static GroupWrite = "group.write";
  static GroupUsersRead = "group.users.read";
  static GroupUsersWrite = "group.users.write";
  static GroupRolesWrite = "group.roles.write";
  static GroupDevicesOwnership = "group.devices.ownership";
}
