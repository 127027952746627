import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

const ViewToggle = ({ viewMode, onViewChange }) => {
  const { t } = useTranslation("common");
  return (
    <ButtonGroup className="view-toggle mb-4">
      <Button
        color="primary"
        active={viewMode === "map"}
        onClick={() => onViewChange("map")}
      >
        <i className="fas fa-map-marked-alt mr-2"></i>
        {t("dashboard.mapView")}
      </Button>
      <Button
        color="primary"
        active={viewMode === "list"}
        onClick={() => onViewChange("list")}
      >
        <i className="fas fa-list-ul mr-2"></i>
        {t("dashboard.listView")}
      </Button>
    </ButtonGroup>
  );
};

export default ViewToggle;
