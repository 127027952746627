import React from "react";
import {connect} from "react-redux";
import {
    Collapse,
    Table,
    Button,
    Modal,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    Input,
} from "reactstrap";
import SelectSearch, {fuzzySearch} from "react-select-search";
import validator from "validator";

import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";
import Loader from "components/Loader";
import DigitalThreshold from "./Forms/DigitalThreshold";
import AnalogThreshold from "./Forms/AnalogThreshold";

import {withTranslation} from "react-i18next";

import "./deviceThresholds.css";
import {AuthorizationContext} from "../contexts/AuthorizationContext";

function renderSearchVariableOption(props, option, snapshot, className) {
    return (
        <button {...props} className={className} type="button">
      <span className="text-danger search-item-alarm">
        {option.unit && option.unit === "alarm" ? keys.ICON_ERROR : null}
      </span>
            <span className="search-item-name">
        {option.name} {option.icon}
      </span>
        </button>
    );
}

class DeviceThresholds extends React.Component {
    static contextType = AuthorizationContext;

    state = {
        isOpen: this.props.isOpen || false,
        loading: false,
        receivers: "",
        showModal: false,
        modalMessage: "",
        modalContent: "virtual",
        loader: {},
        modify: {},
        variable: "",
        variableType: "bit",
        minValue: "",
        maxValue: "",
        rangePercentage: "",
        rangePercentageError: false,
        receiversError: false,
        receiversErrorMessage: "",
        loadingThresholds: false,
        varType: null,
    };

    toggleControllerDetails() {
        const newOpenState = !this.state.isOpen;
        this.setState({isOpen: newOpenState});
    }

    async createAllDefaultThresholds(receivers) {
        this.setState({loading: true});

        const thresholds = (await api.setDefaultThresholds(this.props.guid)).data;
        let thNotify = [];
        if (receivers.length > 0) {
            for (const threshold of thresholds) {
                for (const r of receivers) {
                    thNotify.push({
                        enableNotification: "ON",
                        thresholdGuid: threshold.thresholdGuid,
                        email: r,
                        organizationId: this.context.activeOrganization.guid,
                        siteId: this.props.selectedSiteGuid,
                    });
                }
            }
            await api.createOrUpdateThresholdNotify(thNotify);
        }

        if (this.props.updateStore) this.props.updateStore();

        this.setState({loading: false, isOpen: false});
    }

    onModifyClicked(threshold) {
        if (this.state.modify[threshold.thresholdGuid])
            this.setState({
                modify: {
                    [threshold.thresholdGuid]:
                        !this.state.modify[threshold.thresholdGuid],
                },
            });
        else this.setState({modify: {[threshold.thresholdGuid]: true}});
    }

    async onDeleteClicked(guid) {
        const thresholdGuid = guid;
        // Deleting the threshold we will automatically delete
        // the connected threshold-notify items so there is no need
        // to call the API to remove the threshold-notify
        this.setState({loader: {[thresholdGuid]: true}});
        await api.deleteThreshold(thresholdGuid);
        if (this.props.updateStore) this.props.updateStore();
    }

    showType(th) {
        const vtype = this.props.store.controller.pointsById[th.fieldName]
            ? this.props.store.controller.pointsById[th.fieldName].vtype
            : null;
        const isRising =
            th.maxValue === 0.5 && (th.minValue === null || th.minValue < 0);
        return vtype && vtype === "alarm" && isRising
            ? keys.ICON_CONTROLLER
            : keys.ICON_VIRTUAL_ALARM;
    }

    checkEnable(thGuid) {
        const isEnabled =
            this.props.notifications.filter((thNotify) => {
                return (
                    thNotify.threshold.thresholdGuid === thGuid &&
                    thNotify.enableNotification === "ON"
                );
            }).length > 0;
        return (
            <div
                className={
                    isEnabled
                        ? "icon icon-sm icon-shape bg-success text-white"
                        : "icon icon-sm icon-shape bg-warning text-white"
                }
            >
                {/* {isEnabled ? keys.ICON_OK : keys.ICON_ERROR} */}
                {keys.ICON_OK}
            </div>
        );
    }

    getRTNValue(value, percentage, min) {
        if (!min) {
            return value - (Math.abs(value) * percentage) / 100;
        } else {
            return value + (Math.abs(value) * percentage) / 100;
        }
    }

    showCondition(th) {
        let thresholdType = utils.getThresholdType(
            th.maxValue,
            th.minValue,
            th.rangePercentage
        );

        let {maxValue, minValue} = th;
        let rtnMaxValue;
        let rtnMinValue;

        const point = this.props.store.controller.pointsById[th.fieldName] || null;

        if (point && point.type === "integer" && point.vtype) {
            maxValue =
                utils.pointValue(maxValue, point.type, point.vtype) +
                utils.getUnitByType(0, point.vtype);
            minValue =
                utils.pointValue(minValue, point.type, point.vtype) +
                utils.getUnitByType(0, point.vtype);
            rtnMaxValue =
                utils.pointValue(
                    this.getRTNValue(th.maxValue, th.rangePercentage, false),
                    point.type,
                    point.vtype
                ) + utils.getUnitByType(0, point.vtype);
            rtnMinValue =
                utils.pointValue(
                    this.getRTNValue(th.minValue, th.rangePercentage, true),
                    point.type,
                    point.vtype
                ) + utils.getUnitByType(0, point.vtype);
        }

        if (thresholdType === 1) {
            return (
                <>
                    <span>{this.props.t("deviceThresholds.activeOnRisingEdge")}</span>
                </>
            );
        } else if (thresholdType === 2) {
            return (
                <>
                    <span>{this.props.t("deviceThresholds.activeOnFallingEdge")}</span>
                </>
            );
        } else
            return (
                <>
                    {th.maxValue ? (
                        <>
              <span>
                {`${this.props.t("deviceThresholds.alarm")} ON: ≥ ${maxValue} - ${this.props.t("deviceThresholds.alarm")} OFF: ≤ ${rtnMaxValue}`}
              </span>
                            <br/>
                        </>
                    ) : null}
                    {th.minValue ? (
                        <>
              <span>
                {`${this.props.t("deviceThresholds.alarm")} ON: ≤ ${minValue} - ${this.props.t("deviceThresholds.alarm")} OFF: ≥ ${rtnMinValue}`}
              </span>
                            <br/>
                        </>
                    ) : null}
                    {th.rangePercentage ? (
                        <span>
              {this.props.t("deviceThresholds.rtn") +
                  ": " +
                  th.rangePercentage +
                  "%"}
            </span>
                    ) : null}
                </>
            );
    }

    isReadOnly = () =>
        !this.context.hasWritePermission(
            this.props.store.controller.gateway.gatewayGuid
        );

    showRowButtons(threshold) {
        return (
            <>
                <Button
                    size="sm"
                    name="modify"
                    color="info"
                    value={threshold.thresholdGuid}
                    onClick={this.onModifyClicked.bind(this, threshold)}
                    className="btn btn-icon btn-primary"
                    disabled={this.isReadOnly()}
                >
                    {keys.ICON_EDIT}
                </Button>
                <Button
                    size="sm"
                    name="delete"
                    color="info"
                    value={threshold.thresholdGuid}
                    onClick={this.onDeleteClicked.bind(this, threshold.thresholdGuid)}
                    className="btn btn-icon btn-primary"
                    disabled={this.isReadOnly()}
                >
                    {keys.ICON_TRASH}
                </Button>
            </>
        );
    }

    renderModifyForm(th, thReceivers) {
        const vtype = this.props.store.controller.pointsById[th.fieldName]
            ? this.props.store.controller.pointsById[th.fieldName].vtype
            : null;
        const type = this.props.store.controller.pointsById[th.fieldName]
            ? this.props.store.controller.pointsById[th.fieldName].type
            : null;

        if (
            vtype === "alarm" ||
            type === "bit" ||
            th.fieldName === "noLink" ||
            th.fieldName === "noInternet"
        ) {
            // This is the short format for built-in (from device) alarm variables
            // or digital variables
            const mode =
                th.maxValue === 0.5 && (th.minValue === null || th.minValue < 0)
                    ? "rising"
                    : "falling";
            return (
                <DigitalThreshold
                    selectedSiteGuid={this.props.selectedSiteGuid}
                    key={th.thresholdGuid}
                    th={th}
                    receivers={thReceivers.join("; ")}
                    mode={mode}
                    vtype={vtype}
                    onApplyClick={() => this.props.updateStore()}
                    t={this.props.t}
                />
            );
        }
        // if here, we are rendering analog thresholds
        return (
            <AnalogThreshold
                selectedSiteGuid={this.props.selectedSiteGuid}
                th={th}
                key={th.thresholdGuid}
                receivers={thReceivers}
                onApplyClick={() => this.props.updateStore()}
                t={this.props.t}
            />
        );
    }

    renderRows() {
        return this.props.data.thresholds.map((threshold) => {
            const guid = threshold.thresholdGuid;
            const showModify = this.state.modify[guid] === true;
            const thReceivers = this.props.notifications
                .filter((thNotify) => thNotify.threshold.thresholdGuid === guid)
                .map((thNotify) => thNotify.email);
            if (this.state.loader[threshold.thresholdGuid])
                return (
                    <React.Fragment key={guid}>
                        <tr>
                            <td colSpan={6}>
                                <Loader local size={60}/>
                            </td>
                        </tr>
                    </React.Fragment>
                );
            else
                return (
                    <React.Fragment key={guid}>
                        <tr className="text-center">
                            <td>{this.showRowButtons(threshold)}</td>
                            <td>{this.showType(threshold)}</td>
                            <td>{this.checkEnable(guid)}</td>
                            <td>
                                {utils.getLangText(
                                    threshold.fieldName,
                                    this.props.store.controller.customizationData
                                )}
                            </td>
                            <td>{this.showCondition(threshold)}</td>
                        </tr>
                        <tr>
                            <td
                                colSpan={6}
                                className={showModify ? "d-table-cell" : "d-none"}
                            >
                                <Collapse isOpen={showModify}>
                                    {this.renderModifyForm(threshold, thReceivers)}
                                </Collapse>
                            </td>
                        </tr>
                    </React.Fragment>
                );
        });
    }

    handleAddAllDeviceAlarmsClick(evt) {
        evt.stopPropagation();
        this.setState({modalContent: "predefined"});
        this.toggleModal();
    }

    handleCreateVirtual(evt) {
        evt.stopPropagation();
        this.setState({modalContent: "virtual"});
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            modalMessage: "",
            variable: "",
            variableType: "",
            receivers: "",
            minValue: "",
            maxValue: "",
            rangePercentage: "",
            varType: "",
            maxValueError: false,
            minValueError: false,
            rangePercentageError: false,
            receiversError: false,
            receiversErrorMessage: "",
        });
    };

    validateReceivers(value) {
        let error = false;
        let errorMessage = "";

        const receiversList = utils.getAlertReceiversArray(value);
        for (const r of receiversList) {
            if (validator.isEmail(r) === false) {
                error = true;
                errorMessage = this.props.t("report.invalidEmailAddresses");
                break;
            }
        }

        const maxRecipients = this.context.getSubscriptionFeatureValue(
            "maxEmailRecipientsPerSite"
        ) ?? 0;
        if (
            !error && !this.context.isSuperAdmin() &&
            (maxRecipients !== -1 &&
                receiversList.length > maxRecipients)
        ) {
            error = true;
            errorMessage = `${this.props.t("deviceThresholds.maxEmailRecipients")}: ${maxRecipients}`;
        }

        return {error, errorMessage, receiversList};
    }

    validateRange(minValue, maxValue) {
        const mininumValue = Number.parseFloat(minValue);
        const maximumValue = Number.parseFloat(maxValue);

        const rangeError =
            !isNaN(mininumValue) &&
            !isNaN(maximumValue) &&
            mininumValue > maximumValue;

        return {mininumValue, maximumValue, rangeError};
    }

    onInputChange(evt) {
        const target = evt.target;
        const name = target.name;
        const value = target.value;
        if (name === "receivers") {
            const {error, errorMessage} = this.validateReceivers(value);
            this.setState({
                [name]: value,
                receiversError: error,
                receiversErrorMessage: errorMessage,
            });
        } else if (name === "minValue" || name === "maxValue") {
            const minVal = name === "minValue" ? value : this.state.minValue;
            const maxVal = name === "maxValue" ? value : this.state.maxValue;
            const {rangeError} = this.validateRange(minVal, maxVal);
            this.setState({
                [name]: value,
                minValueError: rangeError,
                maxValueError: rangeError,
            });
        } else if (name === "rangePercentage") {
            this.setState({
                [name]: value,
                rangePercentageError: parseFloat(value) < 1 || parseFloat(value) > 99,
            });
        } else this.setState({[name]: value});
    }

    async onAddVirtualAlarmClick() {
        const receivers = utils.getAlertReceiversArray(this.state.receivers);

        let {minValue, maxValue} = this.state;

        //if (receivers.length) {
        let minv, maxv, rangep;
        if (this.state.variableType === "bit") {
            // Virtual Alert on a Digital variable
            const isRising =
                this.state[this.state.variable + "_radio"] === "undefined" ||
                this.state[this.state.variable + "_radio"] === "rising";
            minv = isRising ? null : 0.5;
            maxv = isRising ? 0.5 : null;
            rangep = 1;
        } else {
            minValue = utils.convertValueForThresholds(minValue, this.state.varType);
            maxValue = utils.convertValueForThresholds(maxValue, this.state.varType);

            // Virtual Alert on Analog variable
            minv = minValue;
            maxv = maxValue;
            rangep = this.state.rangePercentage;
        }

        let th = {
            deviceGuid: this.props.guid,
            label: utils.getLangText(
                this.state.variable,
                this.props.store.controller.customizationData
            ),
            fieldName: this.state.variable,
            minValue: minv,
            maxValue: maxv,
            rangePercentage: rangep,
        };

        const response = await api.createNewThresholds([th]);

        this.toggleModal();

        if (response.status !== 201) {
            this.setState({loading: false, loadingThresholds: false});
            return;
        }

        const thData = response.data.data[0];

        if (receivers.length > 0) {
            const thNotify = receivers.map((rec_email) => {
                return {
                    email: rec_email,
                    enableNotification: "ON",
                    thresholdGuid: thData.thresholdGuid,
                    organizationId: this.context.activeOrganization.guid,
                    siteId: this.props.selectedSiteGuid,
                };
            });

            await api.createOrUpdateThresholdNotify(thNotify);
        }

        if (this.props.updateStore) this.props.updateStore();
        //} else this.setState({ modalMessage: "Receivers list is required" });
    }

    async onEnablePredefinedAlarmsClick() {
        const receivers = utils.getAlertReceiversArray(this.state.receivers);
        //if (receivers.length) {
        // TODO Check for valid emails on the array
        this.toggleModal();
        await this.createAllDefaultThresholds(receivers);
        // } else {
        //   this.setState({
        //     showAddAllDeviceAlarmsMessage: "Receivers list is empty",
        //   });
        // }
    }

    onVarSelected(varCode) {
        this.setState({
            variable: varCode,
            varType:
                varCode === "noLink" || varCode === "noInternet"
                    ? null
                    : this.props.store.controller.pointsById[varCode].vtype || null,
            variableType:
                varCode === "noLink" || varCode === "noInternet"
                    ? "bit"
                    : this.props.store.controller.pointsById[varCode].type,
            [varCode + "_radio"]:
                varCode === "noLink" || varCode === "noInternet"
                    ? "rising"
                    : this.props.store.controller.pointsById[varCode].type === "bit"
                        ? "rising"
                        : "",
        });
    }

    toggleLoader = (status = false) => {
        this.setState({loadingThresholds: status});
    };

    renderVirtualModalContent() {
        let selectOptions = Object.values(
            this.props.store.controller.pointsById
        ).map((data) => {
            let unit = data.vtype || null;

            return {
                label: utils.getLangText(
                    data.code,
                    this.props.store.controller.customizationData
                ),
                value: data.code,
                name: utils.getLangText(
                    data.code,
                    this.props.store.controller.customizationData
                ),
                icon: data.type === "bit" ? keys.ICON_HDD : keys.ICON_ANALOG,
                unit: unit,
            };
        });
        selectOptions.push({
            label: utils.getLangText(
                "noLink",
                this.props.store.controller.customizationData
            ),
            value: "noLink",
            name: utils.getLangText(
                "noLink",
                this.props.store.controller.customizationData
            ),
            icon: keys.ICON_HDD,
        });
        selectOptions.push({
            label: utils.getLangText(
                "noInternet",
                this.props.store.controller.customizationData
            ),
            value: "noInternet",
            name: utils.getLangText(
                "noInternet",
                this.props.store.controller.customizationData
            ),
            icon: keys.ICON_HDD,
        });

        const guid = this.state.variable;

        let unit = null;

        let sel = selectOptions.filter((o) => o.value === this.state.variable);

        if (sel.length > 0 && sel[0].unit && sel[0].unit !== null) {
            unit = utils.getUnitByType(0, sel[0].unit.toLowerCase()).trim();
        }

        return (
            <Form>
                <FormGroup>
                    <SelectSearch
                        filterOptions={fuzzySearch}
                        options={selectOptions}
                        renderOption={renderSearchVariableOption}
                        name="variable"
                        placeholder={this.props.t("deviceThresholds.selectVariable")}
                        search={true}
                        onChange={this.onVarSelected.bind(this)}
                        autoComplete="on"
                        value={this.state.variable}
                    />
                    {this.state.variable !== "" ? (
                        this.state.variableType === "integer" ? (
                            <InputGroup className="mt-3 mb-3 threshold-inputs">
                <span className="threshold-input">
                  <InputGroupText>{keys.ICON_MIN}</InputGroupText>
                  <Input
                      placeholder={
                          this.props.t("deviceThresholds.minimumValue") +
                          (unit ? " (" + unit + ")" : "")
                      }
                      name="minValue"
                      type="number"
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.minValue}
                      className={
                          this.state.minValueError
                              ? " bg-warning text-white "
                              : null
                      }
                  />
                </span>
                                <span className="threshold-input">
                  <InputGroupText>{keys.ICON_MAX}</InputGroupText>
                  <Input
                      placeholder={
                          this.props.t("deviceThresholds.maximumValue") +
                          (unit ? " (" + unit + ")" : "")
                      }
                      name="maxValue"
                      type="number"
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.maxValue}
                      className={
                          this.state.maxValueError
                              ? " bg-warning text-white "
                              : null
                      }
                  />
                </span>

                                <span className="threshold-input">
                  <InputGroupText>{keys.ICON_PERCENT}</InputGroupText>
                  <Input
                      placeholder={this.props.t(
                          "deviceThresholds.returnToNormalPercentage"
                      )}
                      name="rangePercentage"
                      type="number"
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.rangePercentage}
                      className={
                          this.state.rangePercentageError
                              ? " bg-warning text-white "
                              : null
                      }
                  />
                </span>
                            </InputGroup>
                        ) : (
                            // <AnalogThreshold className="mt-3" newThreshold noButton />
                            this.state.variable?.trim().toLowerCase() !== "nointernet" && (
                                <>
                                    <div className="custom-control custom-control-alternative custom-radio mt-3 mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={guid + "_radioRising"}
                                            name={guid + "_radio"}
                                            type="radio"
                                            value="rising"
                                            checked={
                                                (this.state[guid + "_radio"] &&
                                                    this.state[guid + "_radio"] === "rising") ||
                                                this.state[guid + "_radio"] === "undefined"
                                            }
                                            onChange={this.onInputChange.bind(this)}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={guid + "_radioRising"}
                                        >
                                            {this.props.t("deviceThresholds.activeOnRisingEdge")}
                                        </label>
                                    </div>
                                    <div className="custom-control custom-control-alternative custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={guid + "_radioFalling"}
                                            name={guid + "_radio"}
                                            type="radio"
                                            value="falling"
                                            checked={this.state[guid + "_radio"] === "falling"}
                                            onChange={this.onInputChange.bind(this)}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={guid + "_radioFalling"}
                                        >
                                            {this.props.t("deviceThresholds.activeOnFallingEdge")}
                                        </label>
                                    </div>
                                </>
                            )
                        )
                    ) : null}
                    {this.state.variable !== "" ? this.renderReceiversInput() : null}
                </FormGroup>
            </Form>
        );
    }

    renderPredefinedModalContent() {
        return (
            <Form>
                <div className="h4 mb-3 font-weight-200">
                    {this.props.t("deviceThresholds.multipleReceivers") +
                        ' "' +
                        this.props.t("deviceThresholds.setDefault") +
                        '"'}
                </div>
                <FormGroup>{this.renderReceiversInput()}</FormGroup>
            </Form>
        );
    }

    renderReceiversInput() {
        return (
            <>
                <InputGroup>
                    <InputGroupText>{keys.ICON_RECEIVERS}</InputGroupText>
                    <Input
                        name="receivers"
                        value={this.state.receivers}
                        placeholder={this.props.t(
                            "deviceThresholds.multipleReceiversPlaceHolder"
                        )}
                        type="text"
                        onChange={this.onInputChange.bind(this)}
                        className={
                            this.state.receiversError ? " bg-warning text-white " : null
                        }
                    />
                </InputGroup>
                {this.state.receiversError && this.state.receiversErrorMessage && (
                    <div className="text-warning">
                        {this.state.receiversErrorMessage}
                    </div>
                )}
            </>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data && this.state.loadingThresholds)
            this.setState({loadingThresholds: false});
    }

    render() {
        const {controller} = this.props.store;
        const {friendlyName} = controller;
        const virtualNumber = this.props.data.thresholds.filter(
            (th) =>
                (controller.pointsById[th.fieldName] &&
                    controller.pointsById[th.fieldName].vtype !== "alarm") ||
                th.fieldName.toLowerCase() === "nolink" ||
                th.fieldName.toLowerCase() === "nointernet"
        ).length;

        return (
            <>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={() => this.toggleModal()}
                    className="modal-alerts"
                >
                    <div className="modal-header">
                        <h3 className="modal-title" id="modal-title-default">
                            {this.state.modalContent === "virtual"
                                ? this.props.t("deviceThresholds.addVirtualAlert")
                                : this.props.t("deviceThresholds.resetToDefault")}
                            <div className="h4">
                <span className="mr-2">
                  {utils.getModelFromMFVE(this.props.data.model)}
                </span>
                                <span
                                    className={`${controller.isDongleController ? "d-none" : ""}`}
                                >
                  {`${this.props.t("deviceThresholds.reference")}: ${this.props.data.address}`}
                </span>
                            </div>
                        </h3>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.modalContent === "virtual"
                            ? this.renderVirtualModalContent()
                            : this.renderPredefinedModalContent()}
                        {this.state.modalMessage !== "" ? (
                            <h5 color="danger">{this.state.modalMessage}</h5>
                        ) : null}
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                                this.setState({loadingThresholds: true});
                                this.state.modalContent === "virtual"
                                    ? this.onAddVirtualAlarmClick()
                                    : this.onEnablePredefinedAlarmsClick();
                            }}
                            disabled={
                                //this.state.receivers === "" ||
                                this.state.receiversError ||
                                this.state.loadingThresholds ||
                                (this.state.modalContent === "virtual" &&
                                    (this.state.variable === "" ||
                                        (this.state.variableType === "integer" &&
                                            (this.state.minValueError ||
                                                this.state.maxValueError ||
                                                (this.state.minValue === "" &&
                                                    this.state.maxValue === "") ||
                                                this.state.rangePercentage === "" ||
                                                this.state.rangePercentageError))))
                            }
                        >
                            {this.state.modalContent === "virtual"
                                ? this.props.t("deviceThresholds.createVirtual")
                                : this.props.t("deviceThresholds.setDefault")}
                        </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal()}
                        >
                            {this.props.t("deviceThresholds.close")}
                        </Button>
                    </div>
                </Modal>
                <div className="mb-2 border" color="primary">
                    <div
                        className="btn btn-block bg-primary text-white"
                        onClick={this.toggleControllerDetails.bind(this)}
                    >
                        <div className="d-flex">
                            <div className="d-flex background-primary ml-xs--1">
                                {this.props.defaultAvailable ? (
                                    <Button
                                        color="info"
                                        size="sm"
                                        className="btn btn-icon btn-info"
                                        onClick={this.handleAddAllDeviceAlarmsClick.bind(this)}
                                        disabled={this.isReadOnly()}
                                    >
                    <span className="btn-inner--icon">
                      {keys.ICON_CONTROLLER}
                    </span>
                                        <span className="d-none d-md-inline btn-inner--text">
                      {this.props.t("deviceThresholds.default")}
                    </span>
                                    </Button>
                                ) : null}
                                <Button
                                    color="info"
                                    size="sm"
                                    className="btn btn-icon btn-info"
                                    onClick={this.handleCreateVirtual.bind(this)}
                                    disabled={this.isReadOnly()}
                                >
                  <span className="btn-inner--icon">
                    {keys.ICON_VIRTUAL_ALARM}
                  </span>
                                    <span className="d-none d-md-inline btn-inner--text ">
                    {this.props.t("deviceThresholds.new")}
                  </span>
                                </Button>
                            </div>
                            <div className="ml-xs-1 ml-md-4">
                                {friendlyName === null || friendlyName === "" ? (
                                    <span className="h4 text-white">
                    {utils.getModelFromMFVE(this.props.data.model)}
                  </span>
                                ) : (
                                    <span>
                    <span className="h4 text-white">{friendlyName}</span>
                    <br/>
                    <span className="ml-2 h5 text-white font-weight-light">
                      {utils.getModelFromMFVE(this.props.data.model)}
                    </span>
                  </span>
                                )}
                            </div>
                            <div className="ml-xs-1 ml-md-4">
                <span className="h5 text-white">
                  {this.props.t("deviceThresholds.defined") +
                      ": " +
                      this.props.data.thresholds.length}
                </span>
                                <span className="h5 text-white d-md-none">
                  {" ( " + virtualNumber + " )"}
                </span>
                                <span className="h5 text-white d-none d-md-inline">
                  {" ( " +
                      (virtualNumber === 0
                          ? this.props.t("deviceThresholds.noVirtual")
                          : virtualNumber +
                          " " +
                          this.props.t("deviceThresholds.virtual")) +
                      " )"}
                </span>
                                <br/>
                                <span
                                    className={`${controller.isDongleController ? "d-none" : ""} h5 text-white`}
                                >
                  {`${this.props.t("deviceThresholds.reference")}: ${this.props.data.address}`}
                </span>
                            </div>
                        </div>
                    </div>

                    <Collapse isOpen={this.state.isOpen}>
                        <Table className="align-items-center" responsive hover>
                            <thead className="thead-light">
                            <tr className="text-center">
                                <th scope="col">
                                    {this.props.t("deviceThresholds.actions")}
                                </th>
                                <th scope="col">{this.props.t("deviceThresholds.type")}</th>
                                <th scope="col">
                                    {this.props.t("deviceThresholds.enabled")}
                                </th>
                                <th scope="col">
                                    {this.props.t("deviceThresholds.connectedTo")}
                                </th>
                                <th scope="col">
                                    {this.props.t("deviceThresholds.condition")}
                                </th>
                            </tr>
                            </thead>
                            <tbody>{this.renderRows()}</tbody>
                        </Table>
                    </Collapse>
                </div>
                {this.state.loadingThresholds ? <Loader/> : null}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const {guid} = ownProps;

    return {
        store: {
            controller: state.controllers.byId[guid],
        },
    };
};

export default withTranslation("common")(
    connect(mapStateToProps, null)(DeviceThresholds)
);
