import React, {useCallback, useState, useContext} from "react";
import {Button, Form, InputGroup, InputGroupText, Input} from "reactstrap";
import validator from "validator";

import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";

export default function DigitalThreshold({
                                             t,
                                             th: {thresholdGuid, fieldName, label},
                                             onApplyClick,
                                             selectedSiteGuid,
                                             mode,
                                             receivers,
                                             vtype,
                                         }) {
    const {activeOrganization, getSubscriptionFeatureValue, isSuperAdmin} =
        useContext(AuthorizationContext);
    const [originalState, setOriginalState] = useState({
        receiversError: false,
        receiversErrorMessage: "",
        [thresholdGuid + "_radio"]: mode || "rising",
        receivers: receivers || "",
    });
    const [state, setState] = useState(originalState);

    const validateReceivers = useCallback(
        (value) => {
            let error = false;
            let errorMessage = "";

            const receiversList = utils.getAlertReceiversArray(value);
            for (const r of receiversList) {
                if (validator.isEmail(r) === false) {
                    error = true;
                    errorMessage = t("report.invalidEmailAddresses");
                    break;
                }
            }

            const maxRecipients = getSubscriptionFeatureValue(
                "maxEmailRecipientsPerSite"
            ) ?? 0;
            if (
                !error && !isSuperAdmin() &&
                (maxRecipients !== -1 &&
                    receiversList.length > maxRecipients)
            ) {
                error = true;
                errorMessage = `${t("deviceThresholds.maxEmailRecipients")}: ${maxRecipients}`;
            }

            return {error, errorMessage, receiversList};
        },
        [getSubscriptionFeatureValue]
    );

    const onInputChange = useCallback(
        (evt) => {
            const target = evt.target;
            const name = target.name;
            const value = target.value;

            if (name === "receivers") {
                const {error, errorMessage} = validateReceivers(value);
                setState((prev) => ({
                    ...prev,
                    [name]: value,
                    receiversError: error,
                    receiversErrorMessage: errorMessage,
                }));
            } else {
                setState((prev) => ({...prev, [name]: value}));
            }
        },
        [validateReceivers]
    );

    const onSaveChangesClicked = useCallback(
        async (_evt) => {
            const {error, errorMessage} = validateReceivers(state.receivers);
            if (error) {
                setState((prev) => ({
                    ...prev,
                    receiversError: error,
                    receiversErrorMessage: errorMessage,
                }));
                return;
            }

            const same = utils.checkDifferencesOnThreshold(
                state,
                originalState,
                false
            );
            let lastResponse = {status: 200};

            const guid = thresholdGuid;
            if (onApplyClick) {
                if (!same.attributes) {
                    const response = await api.editExistingThreshold({
                        thresholdGuid: guid,
                        rangePercentage: 1,
                        minValue: state[guid + "_radio"] === "falling" ? 0.5 : -1,
                        maxValue: state[guid + "_radio"] === "rising" ? 0.5 : 2,
                        fieldName: fieldName,
                        label: label,
                    });
                    if (response.status !== 200) {
                        setState((prev) => ({
                            ...prev,
                            [guid + "_radio"]: originalState[guid + "_radio"],
                        }));
                        lastResponse = response;
                    }
                }

                const receivers = utils.getAlertReceiversArray(state.receivers);

                if (!same.receivers) {
                    if (receivers.length) {
                        const thnotify = [];
                        for (const r of receivers) {
                            thnotify.push({
                                email: r,
                                thresholdGuid: guid,
                                enableNotification: "ON",
                                organizationId: activeOrganization.guid,
                                siteId: selectedSiteGuid,
                            });
                        }
                        await api.deleteAllThresholdNotifyForThreshold(guid);
                        const response = await api.createOrUpdateThresholdNotify(thnotify);
                        lastResponse = response;
                        if (response.status !== 200) {
                            try {
                                const {data} = (await api.getAllThresholdNotify(guid)).data;
                                const emailList = [];
                                data.forEach((item) => {
                                    emailList.push(item.email);
                                });
                                setState((prev) => ({
                                    ...prev,
                                    receivers: emailList.join("; "),
                                }));
                                setOriginalState((prev) => ({
                                    ...prev,
                                    receivers: emailList.join("; "),
                                }));
                            } catch (ex) {
                                setState((prev) => ({
                                    ...prev,
                                    receivers: originalState.receivers,
                                }));
                            }
                            return;
                        }
                    } else {
                        await api.deleteAllThresholdNotifyForThreshold(guid);
                    }
                }
                onApplyClick();
                if (lastResponse.status === 200) {
                    setOriginalState(state);
                }
            }
        },
        [
            activeOrganization.guid,
            originalState,
            onApplyClick,
            selectedSiteGuid,
            state,
            thresholdGuid,
            validateReceivers,
        ]
    );

    const guid = thresholdGuid;
    const same = utils.checkDifferencesOnThreshold(state, originalState, false);

    return (
        <Form>
            {fieldName.trim().toLowerCase() !== "nointernet" && (
                <>
                    <div className="custom-control custom-control-alternative custom-radio mb-3">
                        <input
                            className="custom-control-input"
                            id={guid + "_radioRising"}
                            name={guid + "_radio"}
                            type="radio"
                            value="rising"
                            checked={state[guid + "_radio"] === "rising"}
                            onChange={onInputChange}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={guid + "_radioRising"}
                        >
                            {t("deviceThresholds.activeOnRisingEdge")}
                            {vtype === "alarm" ? " ( predefined ) " : null}
                        </label>
                    </div>
                    <div className="custom-control custom-control-alternative custom-radio mb-3">
                        <input
                            className="custom-control-input"
                            id={guid + "_radioFalling"}
                            name={guid + "_radio"}
                            type="radio"
                            value="falling"
                            checked={state[guid + "_radio"] === "falling"}
                            onChange={onInputChange}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={guid + "_radioFalling"}
                        >
                            {t("deviceThresholds.activeOnFallingEdge")}
                        </label>
                    </div>
                </>
            )}
            <InputGroup className="mb-3">
                <InputGroupText id="group-name">{keys.ICON_RECEIVERS}</InputGroupText>
                <Input
                    placeholder={t("deviceThresholds.multipleReceiversPlaceHolder")}
                    name="receivers"
                    type="text"
                    onChange={onInputChange}
                    value={state.receivers}
                    className={state.receiversError ? " bg-warning text-white " : null}
                />
            </InputGroup>
            {state.receiversError && state.receiversErrorMessage && (
                <div className="text-warning">{state.receiversErrorMessage}</div>
            )}
            <div className="text-center">
                <Button
                    className="ml-md-5 btn-icon"
                    color="primary"
                    type="button"
                    onClick={onSaveChangesClicked}
                    disabled={state.receiversError || (same.attributes && same.receivers)}
                >
                    <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
                    <span className="btn-inner--text">{t("deviceThresholds.apply")}</span>
                </Button>
            </div>
        </Form>
    );
}
