import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import CardHeader from "components/Headers/CardHeader.js";
import ViewToggle from "components/Dashboard/ViewToggle";
import MapView from "components/Dashboard/MapView";
import ListView from "components/Dashboard/ListView";
import "./dashboard.css";
import { useAuthorizationContext } from "../contexts/AuthorizationContext";

const Dashboard = (props) => {
  const [viewMode, setViewMode] = useState("map");
  const { groups, isSuperAdmin, activeOrganization } = useAuthorizationContext();
  const hasOrganizations = groups.length > 0;
  
  const hasMapView =
    isSuperAdmin() ||
    activeOrganization?.subscription?.features?.mapView === true;

  const { alerts } = props.store;
  let sites = JSON.parse(JSON.stringify(props.store.sites || []));

  sites.forEach((site) => {
    site.alarms = [];
    site.offlineGateways = [];
  });

  Object.keys(alerts.byId).forEach((gatewayGuid) => {
    sites.forEach((site) => {
      site.gateways.forEach((gateway) => {
        if (gateway.gatewayGuid === gatewayGuid) {
          site.alarms.push(alerts.byId[gatewayGuid]);
        }
      });
    });
  });

  sites.forEach((site) => {
    site.gateways = site.gateways.filter(
      (gw) => props.store.gateways.byId[gw.gatewayGuid] !== undefined,
    );
  });

  props.store.gateways.allIds.forEach((gatewayGuid) => {
    if (props.store.gateways.byId[gatewayGuid].status === "OFF") {
      sites.forEach((site) => {
        site.gateways.forEach((gateway) => {
          if (gateway.gatewayGuid === gatewayGuid) {
            site.offlineGateways.push(props.store.gateways.byId[gatewayGuid]);
          }
        });
      });
    }
  });

  const gateways = props.store.gateways.byId;

  return (
    <>
      <Container fluid className="mt--9">
        { hasOrganizations && <CardHeader />}
         <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              { hasOrganizations && (
                  <CardBody>
                {hasMapView && (
                  <div className="view-toggle-container">
                    <ViewToggle
                      viewMode={viewMode}
                      onViewChange={setViewMode}
                    />
                  </div>
                )}

                <div className="view-container">
                  {hasMapView && viewMode === "map" ? (
                    <div className="map-container">
                      <MapView sites={sites} location={props.location} />
                    </div>
                  ) : (
                    <ListView sites={sites} gateways={gateways} />
                  )}
                </div>
              </CardBody>
              )}
              { !hasOrganizations && (
                <CardBody className="d-flex align-items-center justify-content-center text-center h1" style={{ height: "600px" }}>
                  You are not part of any organization. 
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: {
      sitesLoaded: state.sitesLoaded,
      sites: Object.values(state.sites.byId),
      alerts: state.alerts,
      gateways: state.gateways,
    },
  };
};

export default connect(mapStateToProps)(Dashboard);
