import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import OnDemandReports from "./OnDemandReports";
import ScheduledReports from "./ScheduledReports";
import "./reports.css";
import { AuthorizationContext } from "contexts/AuthorizationContext";

class Reports extends Component {
  static contextType = AuthorizationContext;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "onDemand",
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    
    const subscription = this.context.activeOrganization?.subscription;
    const isSuperAdmin = this.context.isSuperAdmin();
    const onDemandReportsEnabled = isSuperAdmin || subscription?.features?.fqrReportManual === true
    const scheduledReportsEnabled = isSuperAdmin || subscription?.features?.fqrReportScheduled === true
    const userHasWritePermissionOnSite = this.context.hasWritePermission(this.props.site.siteGuid);
    
    return this.props.isReport ? (
      <>
        <Nav tabs className="mt-3 mx-4">
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "onDemand",
              }) + " card-point"}
              onClick={() => this.toggleTab("onDemand")}
            >
              {this.props.t("report.onDemand")}
            </NavLink>
          </NavItem>
          <NavItem>
            {userHasWritePermissionOnSite && (
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "scheduled",
                }) + " card-point"}
                onClick={() => this.toggleTab("scheduled")}
              >
                {this.props.t("report.scheduled")}
              </NavLink>
            )
            }
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="onDemand">
            <OnDemandReports {...this.props} fqrEnabled={onDemandReportsEnabled} />
          </TabPane>
          <TabPane tabId="scheduled">
            {<ScheduledReports {...this.props} fqrEnabled={scheduledReportsEnabled} />}
          </TabPane>
        </TabContent>
      </>
    ) : (
      <OnDemandReports {...this.props} fqrEnabled={onDemandReportsEnabled} />
    );
  }
}

export default Reports;
