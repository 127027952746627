import React, { Component } from "react";
import {
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";
import Loader from "components/Loader";
import api from "services/backendService";
import { withTranslation } from "react-i18next";
import icons from "../../configs/constants";
import "./license.css";
import utils from "../../utils";
import Trademark from "components/Trademark";
import ListDropdown from "components/ListDropdown";
import NumberSelector from "components/NumberSelector";
import Selector from "components/Selector";
import SelectSearch, { fuzzySearch } from "react-select-search";

const newLicenseItem = {
  duration: null,
  file: null,
  content: null,
  count: 1,
};
class License extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      activationCodes: [],
      showCreateModal: false,
      soldFor: null,
      salesNote: "",
      filters: {
        expired: false,
        sold: false,
        valid: false,
        activationCode: null,
      },
      newLicenses: [JSON.parse(JSON.stringify(newLicenseItem))],
      availableLicenses: [],
      filterSelectorStatus: false,
    };
  }

  componentDidMount = async () => {
    await this.getAvailableLicenses();
    await this.getActivationCodes();
  };

  getAvailableLicenses = async () => {
    this.setState({ isLoading: true });
    let response = null;
    try {
      response = await api.getAvailableLicenses();
    } catch (e) {
      this.setState({ isLoading: false });
      utils.debug("Exception: " + JSON.stringify(e));
      return;
    }
    if (response && response.status === 200) {
      this.setState({
        availableLicenses: response.data.data,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  getActivationCodes = async () => {
    this.setState({ isLoading: true });
    let response = null;
    try {
      response = await api.getActivationCodes();
    } catch (e) {
      this.setState({ isLoading: false });
      utils.debug("Exception: " + JSON.stringify(e));
      return;
    }
    if (response && response.status === 200) {
      for (let ac of response.data.data) {
        ac.showDetails = false;
        ac.licenses = utils.setLicensesInfo(ac.licenses);
      }
      const activationCodes = response.data.data.reverse();

      this.setState({ activationCodes, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  toggleCreateModal = (status = undefined) => {
    this.setState({
      showCreateModal:
        status !== undefined ? status : !this.state.showCreateModal,
    });
  };

  selectFile = (i) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".json");
    input.addEventListener("change", (e) => {
      let { newLicenses } = this.state;
      newLicenses[i].file = e.target.value.split(/(\\|\/)/g).pop();
      newLicenses[i].content = new FormData();
      newLicenses[i].content.append("formFile", input.files[0]);
      newLicenses[i].loaded = true;
      this.setState({ newLicenses });
    });

    input.click();
  };

  createItems = async () => {
    try {
      this.setState({ isLoading: true });
      this.toggleCreateModal(false);
      let okCount = 0;

      const activationCode = await api.createActivationCode(
        parseFloat(this.state.soldFor || 0),
        this.state.salesNote
      );

      if (!activationCode.status || activationCode.status !== 201) {
        this.setState({ isLoading: false });
        return;
      }

      okCount++;

      const { activationCodeGuid } = activationCode.data;

      for (let newLicense of this.state.newLicenses) {
        for (let index = 0; index < newLicense.count; index++) {
          let response = await api.createLicense(
            activationCodeGuid,
            parseInt(newLicense.duration),
            newLicense.file ? newLicense.file : null,
            !newLicense.file ? newLicense.content : null
          );

          if (!response) {
            await api.deleteActivationCode(activationCodeGuid);
            this.setState({ isLoading: false });
            utils.debug(
              "Something went wrong. No response after creating license",
              utils.MSG_TYPE_ERR
            );
            return;
          }

          if (response.status && response.status === 201) okCount++;
          else {
            utils.debug(JSON.stringify(response, null, 4), utils.MSG_TYPE_ERR);
          }
        }
      }

      if (
        okCount !==
        this.state.newLicenses.reduce((acc, lic) => {
          return acc + lic.count;
        }, 1)
      )
        utils.debug(
          "Something went wrong. OkCount: " + okCount,
          utils.MSG_TYPE_ERR
        );

      await this.getActivationCodes();
    } catch (e) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleFilter = (filter) => {
    let { filters } = this.state;
    switch (filter) {
      case 0:
        filters.expired = !filters.expired;
        break;
      case 1:
        filters.sold = !filters.sold;
        break;
      case 2:
        filters.valid = !filters.valid;
        break;
      default:
        break;
    }
    this.setState({ filters });
  };

  forgetChanges = () => {
    let { activationCodes } = this.state;

    activationCodes = activationCodes.map((ac) => {
      if (ac.originalSalesNote !== undefined) {
        ac.salesNote = ac.originalSalesNote;
        delete ac.originalSalesNote;
      }

      if (ac.originalSoldFor !== undefined) {
        ac.soldFor = ac.originalSoldFor;
        delete ac.originalSoldFor;
      }

      return ac;
    });

    this.setState({ activationCodes });
  };

  render() {
    const showAll =
      Object.keys(this.state.filters).filter((f) => this.state.filters[f])
        .length === 0;

    const changes =
      this.state.activationCodes.reduce((acc, ac) => {
        if (
          ac.originalSalesNote !== undefined ||
          ac.originalSoldFor !== undefined
        )
          acc++;
        return acc;
      }, 0) > 0;

    return (
      <div>
        {this.state.isLoading ? <Loader /> : null}
        <Modal
          isOpen={this.state.showCreateModal}
          toggle={() => this.toggleCreateModal(false)}
          centered
        >
          <ModalBody>
            <h2>{this.props.t("license.createNewActivationCode")}</h2>
            <h5>{this.props.t("license.createACDescription")}</h5>
            <div>
              <span>{this.props.t("license.soldFor") + ":"}</span>
              <Input
                className="mb-3"
                value={this.state.soldFor || ""}
                placeholder={this.props.t("license.soldFor")}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value)))
                    this.setState({ soldFor: e.target.value });
                }}
              />
              <span>{this.props.t("license.salesNote") + ":"}</span>
              <Input
                type="textarea"
                className="modal-sales-note-text-area"
                value={this.state.salesNote}
                placeholder={this.props.t("license.salesNote")}
                onChange={(e) => {
                  this.setState({ salesNote: e.target.value });
                }}
              />
              <div className="modal-licenses-container">
                <Button
                  className="my-4"
                  color="primary"
                  onClick={() => {
                    let { newLicenses } = this.state;
                    newLicenses.push(
                      JSON.parse(JSON.stringify(newLicenseItem))
                    );
                    this.setState({ newLicenses });
                  }}
                >
                  {this.props.t("license.addLicense")}
                </Button>
                <div className="modal-license">
                  {this.state.newLicenses.map((lic, i) => {
                    return (
                      <div key={i} className="modal-license-list">
                        <div className="modal-license-duration">
                          <InputGroup>
                            <div
                              addontype="prepend"
                              className="modal-license-addon"
                            >
                              <InputGroupText
                                className={`bg-light ${
                                  !lic.duration ? "border-danger opacity-6" : ""
                                }`}
                              >
                                {icons.ICON_DURATION}
                              </InputGroupText>
                            </div>
                            <Input
                              id="modal-license-item"
                              value={lic.duration || ""}
                              onChange={(e) => {
                                let { newLicenses } = this.state;
                                if (!isNaN(parseInt(e.target.value)))
                                  newLicenses[i].duration = e.target.value;

                                this.setState({ newLicenses });
                              }}
                              placeholder={this.props.t("license.duration")}
                              className={`${
                                !lic.duration ? "border-danger opacity-6" : ""
                              }`}
                            />
                          </InputGroup>
                          <NumberSelector
                            value={lic.count}
                            onDecrement={() => {
                              let { newLicenses } = this.state;
                              if (newLicenses[i].count > 1)
                                newLicenses[i].count--;
                              this.setState({ newLicenses });
                            }}
                            onIncrement={() => {
                              let { newLicenses } = this.state;
                              newLicenses[i].count++;
                              this.setState({ newLicenses });
                            }}
                            onChange={(e) => {
                              let { newLicenses } = this.state;
                              if (!isNaN(parseInt(e.target.value)))
                                newLicenses[i].count = parseInt(e.target.value);
                              this.setState({ newLicenses });
                            }}
                          />
                        </div>
                        <div className="modal-license-file">
                          <ListDropdown
                            toggleClass="m-0"
                            toggleMenu="menu-license"
                            title={
                              lic.loaded
                                ? this.props.t("license.loadLicenseFile")
                                : utils.capitalize(lic.file) ||
                                  this.props.t("license.selectLicense") + "..."
                            }
                            listItems={[
                              ...this.state.availableLicenses.map((l) => {
                                return {
                                  text: utils.capitalize(l.licenseName),
                                  command: () => {
                                    let { newLicenses } = this.state;
                                    newLicenses[i].file = l.licenseName;
                                    newLicenses[i].loaded = false;
                                    this.setState({ newLicenses });
                                  },
                                };
                              }),
                              {
                                divider: true,
                              },
                              {
                                text:
                                  this.props.t("license.loadLicenseFile") +
                                  "...",
                                command: () => this.selectFile(i),
                              },
                            ]}
                          />
                          <InputGroup>
                            <div
                              addontype="prepend"
                              className="modal-license-addon"
                            >
                              <InputGroupText className={`bg-light`}>
                                {icons.ICON_LICENSE}
                              </InputGroupText>
                            </div>
                            <Input
                              value={lic.loaded ? lic.file || "" : ""}
                              id="modal-license-item"
                              className={`bg-white`}
                              placeholder={
                                this.props.t("license.licenseFile") + "..."
                              }
                              disabled={true}
                            />
                          </InputGroup>
                        </div>
                        <div className="modal-license-buttons">
                          <Button
                            size="sm"
                            color="danger"
                            className="modal-license-remove"
                            onClick={() => {
                              let { newLicenses } = this.state;
                              newLicenses.splice(i, 1);
                              this.setState({ newLicenses });
                            }}
                          >
                            {icons.ICON_TRASH}
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            className="modal-license-copy ml-0 mt-1"
                            onClick={() => {
                              let { newLicenses } = this.state;
                              newLicenses.push(
                                JSON.parse(JSON.stringify(newLicenses[i]))
                              );
                              this.setState({ newLicenses });
                            }}
                          >
                            {icons.ICON_COPY}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Button
              color="warning"
              className="float-right my-3"
              disabled={
                this.state.newLicenses.length === 0 ||
                this.state.newLicenses.filter((x) => !x.duration || !x.file)
                  .length > 0
              }
              onClick={this.createItems}
            >
              {this.props.t("license.createActivationCodeBtn")}
            </Button>
          </ModalBody>
        </Modal>

        <div className="mt--9 mx-4">
          <Card>
            <CardBody>
              <div className="d-flex flex-row justify-content-between align-items-center mb-4 activation-code-header">
                <div className="d-flex justify-content-center align-items-end">
                  <SelectSearch
                    filterOptions={fuzzySearch}
                    options={[
                      { value: "all", name: this.props.t("license.all") },
                    ].concat(
                      this.state.activationCodes.map((ac) => {
                        return {
                          value: ac.activationCodeGuid,
                          name: ac.activationCodeGuid,
                        };
                      })
                    )}
                    name="activationCode"
                    placeholder={
                      this.props.t("sidebar.search") +
                      " " +
                      this.props.t("license.activationCode") +
                      "..."
                    }
                    value={this.state.filters.activationCode || ""}
                    search={true}
                    onChange={(e) => {
                      let { filters } = this.state;
                      filters.activationCode = e === "all" ? null : e;
                      this.setState({ filters });
                    }}
                    autoComplete="on"
                  />
                  <div className="d-flex flex-column align-items-center">
                    <span>{this.props.t("license.show") + ":"}</span>
                    <Selector
                      id="activation-code"
                      className=""
                      status={this.state.filterSelectorStatus}
                      items={[
                        this.props.t("license.all"),
                        this.props.t("license.onlyAvailable"),
                      ]}
                      toggle={() => {
                        let { filterSelectorStatus, filters } = this.state;

                        filterSelectorStatus = !filterSelectorStatus;

                        filters.valid = !filters.valid;

                        this.setState({
                          filterSelectorStatus,
                          filters,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!changes}
                    onClick={() => {
                      let { activationCodes } = this.state;

                      activationCodes.forEach(async (ac) => {
                        if (
                          ac.originalSalesNote !== undefined ||
                          ac.originalSoldFor !== undefined
                        ) {
                          api.editActivationCode(
                            ac.activationCodeGuid,
                            ac.salesNote,
                            ac.soldFor
                          );

                          if (ac.originalSalesNote !== undefined) {
                            delete ac.originalSalesNote;
                          }

                          if (ac.originalSoldFor !== undefined) {
                            delete ac.originalSoldFor;
                          }
                        }
                      });

                      this.setState({ activationCodes });
                    }}
                  >
                    {this.props.t("usersAdmin.saveChanges")}
                  </Button>
                  <Button disabled={!changes} onClick={this.forgetChanges}>
                    {this.props.t("usersAdmin.forgetChanges")}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.toggleCreateModal(true)}
                  >
                    {this.props.t("license.createActivationCodeBtn")}
                  </Button>
                </div>
              </div>

              <div className="activation-codes-container">
                <Table bordered size="sm" responsive>
                  <thead>
                    <tr>
                      <th>{this.props.t("license.activationCode")}</th>
                      <th>{this.props.t("license.salesNote")}</th>
                      <th>{this.props.t("license.soldFor")}</th>
                      <th className={`${utils.isUserAdmin() ? "" : "d-none"}`}>
                        {this.props.t("license.uploadedAt")}
                      </th>
                      <th>{this.props.t("license.availableLicense")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.activationCodes.map((ac, i) => {
                      if (!showAll) {
                        if (this.state.filters.activationCode) {
                          if (
                            ac.activationCodeGuid !==
                            this.state.filters.activationCode
                          )
                            return null;
                        } else if (
                          (ac.uploadedAt && !this.state.filters.expired) ||
                          (!ac.uploadedAt && !this.state.filters.valid)
                        ) {
                          return null;
                        }
                      }

                      const { licenses } = ac;
                      let licensesName = {};

                      licenses.forEach((lic) => {
                        if (!licensesName[lic.name]) {
                          licensesName = { ...licensesName, [lic.name]: 1 };
                        } else {
                          licensesName[lic.name]++;
                        }
                      });

                      return (
                        <tr key={i}>
                          <td
                            className={`${
                              ac.uploadedAt && "bg-danger opacity-3"
                            }`}
                          >
                            <div
                              className={`border border-light activation-code-details ${
                                ac.showDetails ? "show" : ""
                              }`}
                            >
                              <div className="activation-code-details-container">
                                <div>
                                  <div className="h3 mb-0">
                                    {this.props.t("license.salesNote") + ":"}
                                  </div>
                                  <div>{ac.salesNote}</div>
                                </div>

                                <div className="my-2">
                                  <span className="h3">
                                    {this.props.t("license.uploadedAt") + ": "}
                                  </span>
                                  <span>
                                    {ac.uploadedAt
                                      ? new Date(
                                          ac.uploadedAt
                                        ).toLocaleDateString()
                                      : ""}
                                  </span>
                                </div>

                                <div className="my-3">
                                  <span className="h3">
                                    {this.props.t("license.soldFor") + ": "}
                                  </span>
                                  <span>{ac.soldFor || 0}</span>
                                </div>
                              </div>

                              <div className="h3">
                                {this.props.t("license.license") + ":"}
                              </div>
                              <div className="details-license-list-container">
                                {ac.licenses.map((lic, k) => {
                                  const {
                                    name,
                                    duration,
                                    expirationDate,
                                    activeFrom,
                                    timeLeft,
                                    isActive,
                                    isExpiring,
                                    isExpired,
                                    isCancelled,
                                  } = lic;

                                  return (
                                    <div
                                      key={k}
                                      className={`ml-4 my-2 p-2 border border-light rounded ${
                                          (isExpired || isCancelled)
                                          ? "bg-danger opacity-6 text-white"
                                          : ""
                                      }
                                ${
                                  isExpiring
                                    ? "bg-warning opacity-6 text-white"
                                    : ""
                                }
                                ${
                                  isActive
                                    ? "bg-success opacity-6 text-white"
                                    : ""
                                }${
                                  activeFrom
                                    ? "bg-primary opacity-6 text-white"
                                    : ""
                                }`}
                                    >
                                      <div className="row mb-3">
                                        <span className="col col-4 d-flex flex-column">
                                          <span className="mr-2 font-weight-bold">
                                            {this.props.t(
                                              "license.licenseName"
                                            ) + ":"}
                                          </span>
                                          <span>{name}</span>
                                        </span>
                                        <span className="col col-8 d-flex flex-column">
                                          <span className="mr-2 font-weight-bold">
                                            {this.props.t("license.duration") +
                                              ":"}
                                          </span>
                                          <span>{duration}</span>
                                        </span>
                                      </div>

                                      <div>
                                        <span className="mr-2 font-weight-bold">
                                          {this.props.t(
                                            "license.activationDate"
                                          ) + ":"}
                                        </span>
                                        <span>
                                          {activeFrom
                                            ? new Date(
                                                activeFrom
                                              ).toLocaleDateString()
                                            : ""}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="mr-2 font-weight-bold">
                                          {this.props.t(
                                            "license.expirationDate"
                                          ) + ":"}
                                        </span>
                                        <span>
                                          {activeFrom
                                            ? expirationDate.toLocaleDateString()
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="mt-2">
                                        <span className="mr-2 font-weight-bold">
                                          {this.props.t("license.timeLeft") +
                                            ":"}
                                        </span>
                                        <span>
                                          {activeFrom && isActive && !isExpired  && !isCancelled
                                            ? timeLeft
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <Card className="card-activation-code">
                              <CardBody className="card-body-container">
                                <div
                                  className="icon-activation-code-details text-light"
                                  onClick={async () => {
                                    let { activationCodes } = this.state;
                                    activationCodes[i].showDetails =
                                      !activationCodes[i].showDetails;
                                    this.setState({ activationCodes });
                                  }}
                                >
                                  {icons.ICON_INFO}
                                </div>
                                <div className="trademark-container trademark-admin">
                                  <Trademark className="trademark-logo" />
                                  <div className="trademark-info">
                                    <h5 className="food-service">
                                      Foodservice
                                    </h5>
                                    <h5 className="data-management">
                                      Data Management Cloud
                                    </h5>
                                  </div>
                                </div>
                                <div className="activation-code-info">
                                  <span className="">
                                    {this.props.t("license.license") + ": "}
                                  </span>
                                  <span>
                                    {Object.keys(licensesName).map((l, j) => {
                                      return (
                                        <div
                                          key={`l-${j}`}
                                          className="font-weight-bold"
                                        >
                                          {`${utils.capitalize(
                                            l.toString()
                                          )} x ${licensesName[l]}`}
                                        </div>
                                      );
                                    })}
                                  </span>
                                </div>
                                <div className="activation-code">
                                  <div className="activation-code-label">
                                    {this.props.t("license.activationCode")}:
                                  </div>
                                  <div className="activation-code-guid">
                                    {ac.activationCodeGuid}
                                  </div>
                                </div>
                                {ac.uploadedAt !== null ? (
                                  <div className="license-active">
                                    {this.props.t("license.notAvailable")}
                                  </div>
                                ) : null}
                              </CardBody>
                            </Card>
                          </td>
                          <td className="activation-code-sales-note">
                            <Input
                              className={`activation-code-sales-note-text-area ${
                                ac.originalSalesNote !== undefined &&
                                "sales-note-text-area-changed"
                              }`}
                              type="textarea"
                              value={ac.salesNote || ""}
                              placeholder={this.props.t("license.salesNote")}
                              onChange={(e) => {
                                let { activationCodes } = this.state;

                                if (
                                  activationCodes[i].originalSalesNote ===
                                  undefined
                                ) {
                                  activationCodes[i].originalSalesNote =
                                    activationCodes[i].salesNote || "";
                                }

                                activationCodes[i].salesNote = e.target.value;

                                if (
                                  activationCodes[i].originalSalesNote ===
                                  activationCodes[i].salesNote
                                ) {
                                  delete activationCodes[i].originalSalesNote;
                                }

                                this.setState({ activationCodes });
                              }}
                              disabled={ac.uploadedAt}
                            />
                          </td>
                          <td className="activation-code-sold-for">
                            <Input
                              className={`activation-code-sold-for-input ${
                                ac.originalSoldFor !== undefined &&
                                "sold-for-input-changed"
                              }`}
                              value={ac.soldFor || 0}
                              placeholder={this.props.t("license.soldFor")}
                              onChange={(e) => {
                                let { activationCodes } = this.state;

                                if (
                                  activationCodes[i].originalSoldFor ===
                                  undefined
                                ) {
                                  activationCodes[i].originalSoldFor =
                                    activationCodes[i].soldFor || 0;
                                }

                                if (!Number.isNaN(e.target.value))
                                  activationCodes[i].soldFor = e.target.value;

                                if (
                                  activationCodes[i].originalSoldFor ===
                                  activationCodes[i].soldFor
                                ) {
                                  delete activationCodes[i].originalSoldFor;
                                }

                                this.setState({ activationCodes });
                              }}
                              disabled={ac.uploadedAt}
                            />
                          </td>
                          <td
                            className={`${utils.isUserAdmin() ? "" : "d-none"}`}
                          >
                            {ac.uploadedAt &&
                              new Date(ac.uploadedAt).toLocaleString()}
                          </td>
                          <td>
                            <table>
                              <thead>
                                <tr>
                                  <th>{this.props.t("license.type")}</th>
                                  <th
                                    className={`${
                                      utils.isUserAdmin() ? "" : "d-none"
                                    }`}
                                  >
                                    {this.props.t("license.state")}
                                  </th>
                                  <th>{this.props.t("license.duration")}</th>
                                  <th>
                                    {this.props.t("license.activationDate")}
                                  </th>
                                  <th>
                                    {this.props.t("license.expirationDate")}
                                  </th>
                                  <th
                                    className={`${
                                      utils.isUserAdmin() ? "" : "d-none"
                                    }`}
                                  >
                                    {this.props.t("license.timeLeft")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {ac.licenses.map((lic, j) => {
                                  let licenseState =
                                    utils.getLicenseIconAndColor(lic);

                                  let licenseIcon =
                                    licenseState &&
                                    licenseState.length === 2 &&
                                    licenseState[0];
                                  let licenseIconColor =
                                    licenseState &&
                                    licenseState.length === 2 &&
                                    licenseState[1];

                                  return (
                                    <tr key={`lic-${j}`}>
                                      <td>{lic.name}</td>
                                      <td
                                        className={`${
                                          utils.isUserAdmin() ? "" : "d-none"
                                        }`}
                                      >
                                        <span
                                          className={`license-list-state-icon ${licenseIconColor} text-white`}
                                        >
                                          {licenseIcon}
                                        </span>
                                      </td>
                                      <td>{lic.licenseTime}</td>
                                      <td>
                                        {lic.activeFrom &&
                                          new Date(
                                            lic.activeFrom
                                          ).toLocaleDateString()}
                                      </td>
                                      <td>
                                        {lic.expirationDate &&
                                          new Date(
                                            lic.expirationDate
                                          ).toLocaleDateString()}
                                      </td>
                                      <td
                                        className={`${
                                          utils.isUserAdmin() ? "" : "d-none"
                                        }`}
                                      >
                                        {lic.timeLeft}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(License);
