import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Card, Modal, ModalBody} from "reactstrap";
import {SubscriptionTable} from "./subscriptionTable";
import {SubscriptionDetails} from "./subscriptionDetails";
import {ActivationModal} from "./activationModal";
import api from "../../services/backendService";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";
import Loader from "../Loader";
import utils from "../../utils";
import {ActivationSubscriptionModal} from "./activationSubscriptionModal";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "components/Notify";

export default function Wallet(props) {
    const context = useContext(AuthorizationContext);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalActivationSubscriptOpen, setIsModalActivationSubscriptOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await updateSubscription();
        };

        fetchData();
    }, []);

    const updateSubscription = async () => {
        const customerGuid = context.isSuperAdmin()
            ? null
            : context.activeOrganization.guid;

        api.getSubscriptions(customerGuid)
            .then((sub) => {
                const sortedSubscriptions = sub.data.sort((a, b) => {
                    const order = {Available: 0, Active: 1, Expired: 2, Cancelled: 3};
                    return order[a.status] - order[b.status];
                });
                setSubscriptions(sortedSubscriptions);

                const subscriptionView = sortedSubscriptions.find(sub => sub.status.toLowerCase() === "active") || sortedSubscriptions[0] || null;
                updateSubscriptionDetails(subscriptionView.subscriptionGuid, true);
            })
            .catch((error) => {
                utils.debug(error, utils.MSG_TYPE_WARN);
            })
    };

    const updateSubscriptionDetails = async (subscriptionGuid, force = false) => {
        if (!subscriptionGuid) {
            setSelectedSubscriptionDetails(null);
            return;
        }
        if (!force && subscriptionGuid === selectedSubscriptionDetails?.subscriptionGuid){
            return;
        }
        api.getSubscriptionDetails(subscriptionGuid)
            .then((sub) => {
                setSelectedSubscriptionDetails(sub.data);
            })
            .catch((error) => {
                utils.debug(error, utils.MSG_TYPE_WARN);
            })
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleDetailsModal = () => {
        setIsDetailsModalOpen(!isDetailsModalOpen);
    };

    const handleActivateSubscriptionDetails = (subscriptionGuid) => {
        updateSubscriptionDetails(subscriptionGuid);
        toggleDetailsModal();
    };

    const handleActivateSubscription = async (subscription, force = false) => {
        try {
            setIsLoading(true);
            await api.activateSubscription(subscription.subscriptionGuid, force);
            await updateSubscription();
        } catch (error) {
            if (error.status === 409 && error.data.title.toLowerCase().includes('conflict')) {
                setIsModalActivationSubscriptOpen(true);
            } else {
                notifyStore.addNotification({
                    ...tempOptions,
                    content: NotifyContent("danger", null, error.data.detail),
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container fluid>
            {isLoading ? <Loader/> : null}
            <Card className="options-container mt--9 border-0">
                <div className="d-none d-lg-block">
                    <Row>
                        <Col md={6}>
                            <SubscriptionTable
                                subscriptions={subscriptions}
                                onSubscriptionClick={(subscriptionGuid) =>
                                    updateSubscriptionDetails(subscriptionGuid)}
                                onAddClick={toggleModal}
                                tableFull={context.isSuperAdmin()}
                                t={props.t}
                            />
                        </Col>
                        <Col md={6}>
                            <SubscriptionDetails
                                subscription={selectedSubscriptionDetails}
                                onActivate={handleActivateSubscription}
                                detailsFull={context.isSuperAdmin()}
                                t={props.t}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="d-lg-none">
                    <SubscriptionTable
                        subscriptions={subscriptions}
                        onSubscriptionClick={(subscriptionGuid) =>
                            handleActivateSubscriptionDetails(subscriptionGuid)
                        }
                        tableFull={context.isSuperAdmin()}
                        t={props.t}
                    />
                </div>
                <Modal isOpen={isDetailsModalOpen} toggle={toggleDetailsModal}>
                    <ModalBody>
                        <SubscriptionDetails
                            subscription={selectedSubscriptionDetails}
                            onActivate={handleActivateSubscription}
                            detailsFull={context.isSuperAdmin()}
                            t={props.t}
                        />
                    </ModalBody>
                </Modal>

                <ActivationModal
                    isOpen={isModalOpen}
                    toggle={toggleModal}
                    setLoader={(state) => setIsLoading(state)}
                    organizationGuid={
                        context.isSuperAdmin() ? null : context.activeOrganization.guid
                    }
                    refresh={() => updateSubscription()}
                    t={props.t}
                />
                <ActivationSubscriptionModal
                    isOpen={isModalActivationSubscriptOpen}
                    toggle={() => setIsModalActivationSubscriptOpen(false)}
                    onActivate={() => {
                        handleActivateSubscription(selectedSubscriptionDetails, true)
                        setIsModalActivationSubscriptOpen(false)
                    }
                    }
                    t={props.t}
                />
            </Card>
        </Container>
    );
}