import React from "react";
import { Table, Button, Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import { apiAction, setControllers } from "../../redux/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "services/backendService";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../Notify";
import keys from "configs/constants";

import utils from "utils";

import "./style.css";
import { withTranslation } from "react-i18next";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";

class GatewayTable extends React.Component {
  static contextType = AuthorizationContext;

  state = {
    redirect: false,
    fwUpdateGWList: [],
  };

  gatewayInfo = {
    customer: null,
    devices: null,
    gatewayGuid: null,
    macAddress: null,
    name: null,
    site: null,
    siteGuid: null,
  };

  componentDidMount() {
    let isThereAnyController = false;
    for (const g of Object.values(this.props.store.gateways.byId).filter(
        (gw) => gw.site.siteGuid === this.props.site.siteGuid
    )) {
      if (g.devices.length > 0) {
        isThereAnyController = true;
        break;
      }
    }
    if (
        this.props.store.controllers.byId !== undefined &&
        Object.values(this.props.store.controllers.byId).length === 0 &&
        isThereAnyController
    ) {
      this.props.apiAction(
          api.getDevicesOnSite,
          [this.props.site.siteGuid],
          setControllers,
          true
      );
    }
  }

  onPermLevelChange(level) {
    this.props.onPermLevelChange(level);
  }

  onUpdatePerm() {
    this.props.onUpdatePerm();
  }

  onForgetChanges() {
    this.props.onForgetChanges();
  }

  renderRows() {
    if (this.props.userData.length === 0) return;

    return this.props.userData.map((gw, idx) => {
      const { gateway } = gw;

      const redirect = () => {
        this.gatewayInfo = {
          customer: gateway.customer.name,
          devices: gateway.devices,
          gatewayGuid: gateway.gatewayGuid,
          macAddress: gateway.macAddress,
          name: gateway.name,
          site: gateway.site.name,
          siteGuid: gateway.site.siteGuid,
        };
        this.setState({ redirect: true });
      };

      const pushTelemetry = utils.formatTime(
          gateway.telemetryPushInterval,
          utils.NO_ZERO,
          false
      );

      const isUpdateDisabled = !(
          gateway.status === "ON" &&
          (this.context.isSuperAdmin() ||
              this.context.hasWritePermission(gateway.gatewayGuid))
      );

      const gtwLastStatus = utils.getGatewayLastStatus(gateway.lastStatus);

      return (
          <tr key={idx} onClick={redirect}>
            <td>
              {gateway.status === "OFF" ? (
                  <span className="text-warning mr-2 h2">{keys.ICON_WARNING}</span>
              ) : (
                  ""
              )}
              {gateway.name}
            </td>
            <td>
              {gtwLastStatus === "unknown" || gateway.status === "OFF" ? (
                  <span className="text-light">
                {" "}
                    {this.props.t(`gatewayTable.unavailable`)}
              </span>
              ) : (
                  <Badge
                      color={gtwLastStatus === "licenseNotValid" ? "warning" : "info"}
                      className="text-white"
                  >
                <span className="font-weight-bold">
                  {" "}
                  {this.props.t(`gatewayStatus.${gtwLastStatus}`)}
                </span>
                  </Badge>
              )}
            </td>
            <td className={`${gateway.releaseFW ? "" : "text-light"}`}>
              {gateway.releaseFW || this.props.t("gatewayTable.unavailable")}
            </td>
            <td>{pushTelemetry}</td>
            <td>{gateway.devices.filter((x) => !x.isDongleController).length}</td>
            <td>{gateway.macAddress}</td>
            <td>
              <Button
                  color="primary"
                  disabled={isUpdateDisabled}
                  onClick={async (e) => {
                    e.stopPropagation();

                    const r = await api.sendMessageToGateway(
                        gateway.gatewayGuid,
                        { type: "gcmd", cmd: "fwupdate" },
                        false
                    );

                    notifyStore.addNotification({
                      ...tempOptions,
                      content: NotifyContent(
                          r.status === 200 ? "success" : "danger",
                          null,
                          r.status === 200
                              ? this.props.t("gatewayTable.commandSent")
                              : `${this.props.t("gatewayTable.unableToSend")} ${gateway.name}`
                      ),
                    });
                  }}
              >
                {this.props.t("gatewayTable.updateFw")}
              </Button>
            </td>
          </tr>
      );
    });
  }

  render() {
    const siteInfo = {
      city: this.props.site.city,
      connectivitytype: this.props.site.connectivitytype,
      country: this.props.site.country,
      latitude: this.props.site.latitude,
      longitude: this.props.site.longitude,
      name: this.props.site.name,
      siteGuid: this.props.site.siteGuid,
    };

    if (this.state.redirect) {
      return (
          <Redirect
              to={{
                pathname: "/std/gtw",
                gateway: this.gatewayInfo,
                site: siteInfo,
              }}
          />
      );
    }

    return (
        <div className="border">
          <div className="gateway-table">
            <Table className="align-items-center" responsive="sm" hover>
              <thead className="thead-light">
              <tr>
                <th scope="col">{this.props.t("gatewayTable.name")}</th>
                <th scope="col">
                  {this.props.t("gatewayProperties.lastStatus")}
                </th>
                <th scope="col">{this.props.t("gatewayTable.release")}</th>
                <th scope="col">{this.props.t("gatewayTable.pushInterval")}</th>
                <th scope="col">{this.props.t("gatewayTable.controllers")}</th>
                <th scope="col">{this.props.t("gatewayTable.uniqueId")}</th>
                <th scope="col">
                  {this.props.t("gatewayTable.updateFirmware")}
                </th>
              </tr>
              </thead>
              <tbody>{this.renderRows()}</tbody>
            </Table>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: {
      controllers: state.controllers,
      gateways: state.gateways,
      userRole: state.user.role,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          apiAction,
        },
        dispatch
    );

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(GatewayTable)
);