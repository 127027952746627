import React, {useCallback, useState, useContext} from "react";
import {
    Button,
    Form,
    InputGroup,
    InputGroupText,
    Input,
    FormGroup,
} from "reactstrap";
import validator from "validator";

import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";

export default function AnalogThreshold({
                                            t,
                                            th: {thresholdGuid, fieldName, label, minValue, maxValue, rangePercentage},
                                            receivers,
                                            onApplyClick,
                                            selectedSiteGuid,
                                            noButton,
                                        }) {
    const {activeOrganization, getSubscriptionFeatureValue, isSuperAdmin} =
        useContext(AuthorizationContext);
    const initialState = {
        receiversError: false,
        receiversErrorMessage: "",
        receivers: receivers ? receivers.join("; ") : "",
        minValue: minValue ?? "",
        maxValue: maxValue ?? "",
        rangePercentage: rangePercentage ?? "",
        minValueError: false,
        maxValueError: false,
        rangePercentageError: false,
    };

    const [originalState, setOriginalState] = useState(initialState);
    const [state, setState] = useState(initialState);

    const validateReceivers = useCallback(
        (value) => {
            let error = false;
            let errorMessage = "";

            const receiversList = utils.getAlertReceiversArray(value);
            for (const r of receiversList) {
                if (validator.isEmail(r) === false) {
                    error = true;
                    errorMessage = t("report.invalidEmailAddresses");
                    break;
                }
            }

            const maxRecipients = getSubscriptionFeatureValue(
                "maxEmailRecipientsPerSite"
            ) ?? 0;
            if (
                !error && !isSuperAdmin() &&
                (maxRecipients !== -1 &&
                    receiversList.length > maxRecipients)
            ) {
                error = true;
                errorMessage = `${t("deviceThresholds.maxEmailRecipients")}: ${maxRecipients}`;
            }

            return {error, errorMessage, receiversList};
        },
        [getSubscriptionFeatureValue, t]
    );

    const validateRange = useCallback((minValue, maxValue) => {
        const mininumValue = Number.parseFloat(minValue);
        const maximumValue = Number.parseFloat(maxValue);

        const rangeError =
            !isNaN(mininumValue) &&
            !isNaN(maximumValue) &&
            mininumValue > maximumValue;

        return {mininumValue, maximumValue, rangeError};
    }, []);

    const onInputChange = useCallback(
        (evt) => {
            const target = evt.target;
            const name = target.name;
            const value = target.value;

            if (name === "receivers") {
                const {error, errorMessage} = validateReceivers(value);
                setState((prev) => ({
                    ...prev,
                    [name]: value,
                    receiversError: error,
                    receiversErrorMessage: errorMessage,
                }));
            } else if (name === "rangePercentage") {
                const numValue = Number.parseFloat(value);
                setState((prev) => ({
                    ...prev,
                    [name]: value,
                    rangePercentageError: numValue < 1 || numValue > 99,
                }));
            } else if (name === "minValue" || name === "maxValue") {
                const minVal = name === "minValue" ? value : state.minValue;
                const maxVal = name === "maxValue" ? value : state.maxValue;
                const {rangeError} = validateRange(minVal, maxVal);

                setState((prev) => ({
                    ...prev,
                    [name]: value,
                    minValueError: rangeError,
                    maxValueError: rangeError,
                }));
            } else {
                setState((prev) => ({...prev, [name]: value}));
            }
        },
        [state.minValue, state.maxValue, validateRange, validateReceivers]
    );

    const onSaveChangesClicked = useCallback(
        async (_evt) => {
            const {error, errorMessage} = validateReceivers(state.receivers);
            if (error) {
                setState((prev) => ({
                    ...prev,
                    receiversError: error,
                    receiversErrorMessage: errorMessage,
                }));
                return;
            }

            const same = utils.checkDifferencesOnThreshold(
                state,
                originalState,
                true
            );
            let lastResponse = {status: 200};

            if (onApplyClick) {
                if (!same.attributes) {
                    const response = await api.editExistingThreshold({
                        thresholdGuid: thresholdGuid,
                        rangePercentage: state.rangePercentage,
                        minValue: state.minValue,
                        maxValue: state.maxValue,
                        fieldName: fieldName,
                        label: label,
                    });

                    if (response.status !== 200) {
                        setState((prev) => ({
                            ...prev,
                            minValue: originalState.minValue,
                            maxValue: originalState.maxValue,
                            rangePercentage: originalState.rangePercentage,
                        }));
                        lastResponse = response;
                    }
                }

                const receivers = utils.getAlertReceiversArray(state.receivers);

                if (!same.receivers) {
                    if (receivers.length) {
                        const thnotify = [];
                        for (const r of receivers) {
                            thnotify.push({
                                email: r,
                                thresholdGuid: thresholdGuid,
                                enableNotification: "ON",
                                organizationId: activeOrganization.guid,
                                siteId: selectedSiteGuid,
                            });
                        }
                        await api.deleteAllThresholdNotifyForThreshold(thresholdGuid);
                        const response = await api.createOrUpdateThresholdNotify(thnotify);
                        lastResponse = response;
                        if (response.status !== 200) {
                            try {
                                const {data} = (
                                    await api.getAllThresholdNotify(thresholdGuid)
                                ).data;
                                const emailList = [];
                                data.forEach((item) => {
                                    emailList.push(item.email);
                                });
                                setState((prev) => ({
                                    ...prev,
                                    receivers: emailList.join("; "),
                                }));
                                setOriginalState((prev) => ({
                                    ...prev,
                                    receivers: emailList.join("; "),
                                }));
                            } catch (ex) {
                                setState((prev) => ({
                                    ...prev,
                                    receivers: originalState.receivers,
                                }));
                            }
                            return;
                        }
                    } else {
                        await api.deleteAllThresholdNotifyForThreshold(thresholdGuid);
                    }
                }
                onApplyClick();
                if (lastResponse.status === 200) {
                    setOriginalState(state);
                }
            }
        },
        [
            activeOrganization.guid,
            originalState,
            onApplyClick,
            selectedSiteGuid,
            state,
            validateReceivers,
            thresholdGuid,
            fieldName,
            label,
        ]
    );

    const same = utils.checkDifferencesOnThreshold(state, originalState, true);

    return (
        <Form>
            <FormGroup>
                <InputGroup className="mb-3">
                    <InputGroupText>{keys.ICON_MIN}</InputGroupText>
                    <Input
                        placeholder={t("deviceThresholds.minimumValue")}
                        name="minValue"
                        type="number"
                        onChange={onInputChange}
                        value={state.minValue}
                        className={state.minValueError ? " bg-warning text-white " : null}
                    />
                    <InputGroupText>{keys.ICON_MAX}</InputGroupText>
                    <Input
                        placeholder={t("deviceThresholds.maximumValue")}
                        name="maxValue"
                        type="number"
                        onChange={onInputChange}
                        value={state.maxValue}
                        className={state.maxValueError ? " bg-warning text-white " : null}
                    />
                    <InputGroupText>{keys.ICON_PERCENT}</InputGroupText>
                    <Input
                        placeholder={t("deviceThresholds.returnToNormalPercentage")}
                        name="rangePercentage"
                        type="number"
                        onChange={onInputChange}
                        value={state.rangePercentage}
                        className={
                            state.rangePercentageError ? " bg-warning text-white " : null
                        }
                    />
                </InputGroup>
            </FormGroup>

            <InputGroup className="mb-3">
                <InputGroupText>{keys.ICON_RECEIVERS}</InputGroupText>
                <Input
                    placeholder={t("deviceThresholds.multipleReceiversPlaceHolder")}
                    name="receivers"
                    type="text"
                    onChange={onInputChange}
                    value={state.receivers}
                    className={state.receiversError ? " bg-warning text-white " : null}
                />
            </InputGroup>
            {state.receiversError && state.receiversErrorMessage && (
                <div className="text-warning">{state.receiversErrorMessage}</div>
            )}
            {noButton !== true ? (
                <div className="text-center">
                    <Button
                        className="ml-md-5 btn-icon"
                        color="primary"
                        type="button"
                        disabled={
                            state.receiversError ||
                            state.minValueError ||
                            state.maxValueError ||
                            (state.minValue === "" && state.maxValue === "") ||
                            state.rangePercentage === "" ||
                            state.rangePercentageError ||
                            (same.receivers && same.attributes)
                        }
                        onClick={onSaveChangesClicked}
                    >
                        <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
                        <span className="btn-inner--text">
              {t("deviceThresholds.apply")}
            </span>
                    </Button>
                </div>
            ) : null}
        </Form>
    );
}
